import { Fragment, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Card,
  Typography,
  Grid,
  Box,
  Pagination,
  Alert,
  Button,
  IconButton,
  TableSortLabel,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import { AddUserModal } from 'pages/admin/dashboard/addUser';
import { TableSkeleton } from 'components/common/skeletons';
import { EditIcon, EyeIcon, HubspotIcon, CrossIcon, SearchIcon, CheckIcon, ClockIcon } from 'components';
import { ButtonComponent } from 'components/ui';

import { numberWithCommas } from 'helpers';
import { useDebounceState } from 'helpers/hooks';
import { AdminApi } from 'api';
import { paths } from 'consts';
import moment from 'moment';

const pageSize = 10;
const rowHeight = 73;
const debounceTime = 450;

export const squareBtnStyle = { width: 20, height: 20, minWidth: 20, borderRadius: 0.5, padding: '3px', justifyContent: 'center' };

export const ApiUsersInformation = ({ environment, verified }) => {
  const {
    loading: registrationOptionsLoading,
    data: { type: userTypes },
  } = useSelector((s) => s.registrationOptions);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState('creationDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [loadExcel, setLoadExcel] = useState(false);
  const resetPage = useCallback(() => setPage(1), []);
  const [search, setSearch] = useDebounceState('', debounceTime, resetPage);
  const inpuRef = useRef(null);

  const [editUserData, setEditUserData] = useState(null);

  const onChangeSearch = (e) => setSearch(e.target.value);

  const onClearSearch = () => {
    if (inpuRef && inpuRef.current) {
      try {
        inpuRef.current.value = '';
      } catch (error) {
        console.log(error);
      }
    }
    setSearch('');
  };

  const toggleAddUserModal = () => {
    if (addUserModalOpen && editUserData) {
      setEditUserData(null);
    }
    setAddUserModalOpen(!addUserModalOpen);
  };

  const onEditUser = (editObj) => () => {
    setEditUserData(editObj);
    setAddUserModalOpen(true);
  };

  const onDownloadExcel = async () => {
    setLoadExcel(true);
    const { file, fileName } = await AdminApi.getUsersExel().catch(console.log);
    const link = document.createElement('a');
    link.href = file;
    link.download = fileName;
    link.click();
    setLoadExcel(false);
  };

  const onChangeSort = (sortKey) => () => {
    if (sortBy === sortKey) setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    else setSortBy(sortKey);
    setPage(1);
  };

  const PageContent = useMemo(() => {
    const onNavigateToDetails = (userID) => () => navigate(`/${paths.admin.path}/${paths.users.path}/${userID}`);

    return data.map((user, index) => {
      const { email, companyName, type, creationDate, accountBalance, trialAccountBalance, userId, environment, hubspotLink, lastActivityDate, verified } = user;

      return (
        <TableRow key={'row-' + index + Math.random() + userId} sx={{ height: rowHeight }}>
          <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
            <Typography variant='body1' color='text.dark' mb='4px'>
              {email}
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              {companyName || '-'}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1' color='text.secondary'>
              {type ? userTypes[type] || type : '-'}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Label environment={environment} />
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1'>{numberWithCommas(accountBalance + trialAccountBalance) || 0}</Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1' color='text.secondary'>
              {moment(creationDate).format('DD/MM/YY')}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1' color='text.secondary'>
              {moment(lastActivityDate).format('DD/MM/YY hh:mm A')}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <VerificationStatus status={verified} />
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Grid container flexWrap='nowrap'>
              <Tooltip
                title={
                  <Typography variant='body2' color='text.dark'>
                    Api User Details
                  </Typography>
                }
                arrow
                placement='top'
              >
                <Button size='small' variant='outlined' sx={{ ...squareBtnStyle }} onClick={onNavigateToDetails(userId)}>
                  <EyeIcon />
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  <Typography variant='body2' color='text.dark'>
                    Edit Api User
                  </Typography>
                }
                arrow
                placement='top'
              >
                <Button size='small' variant='contained' sx={{ ...squareBtnStyle, marginX: 1.5 }} onClick={onEditUser(user)}>
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  <Typography variant='body2' color='text.dark'>
                    Hubspot Link
                  </Typography>
                }
                arrow
                placement='top'
              >
                <a target='_blank' href={hubspotLink} rel='noreferrer'>
                  <Button size='small' variant='outlined' sx={{ ...squareBtnStyle }}>
                    <HubspotIcon />
                  </Button>
                </a>
              </Tooltip>
            </Grid>
          </TableCell>
        </TableRow>
      );
    });
  }, [data, navigate, userTypes]);

  const onChangePage = (_, nextPage) => setPage(nextPage);

  const emptyRows = pageSize - data.length;

  const getUsers = (_page = page, _sortBy = sortBy, _sortOrder = sortOrder, _search = search, _environment = environment, _verified = verified, controller) => {
    setLoading(true);
    setError('');
    AdminApi.getUsers(
      { page: _page - 1, pageSize, sortBy: _sortBy, sortOrder: _sortOrder, search: _search, environment: _environment, verified: _verified },
      controller ? controller.signal : null
    )
      .then(({ content, totalPages }) => {
        setData(content);
        setTotalPages(totalPages);
        setLoading(false);
      })
      .catch((err) => {
        if (err !== 'canceled') {
          setError(err);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const controller = new AbortController();

    getUsers(page, sortBy, sortOrder, search, environment, verified, controller);

    return () => controller.abort();
    // eslint-disable-next-line
  }, [page, sortBy, sortOrder, search, environment, verified]);

  return (
    <Fragment>
      <Card sx={{ paddingY: 3, marginBottom: 3 }} variant='outlined'>
        <Box sx={{ paddingX: 4 }}>
          <Grid container justifyContent='space-between' flexWrap='nowrap'>
            <Typography variant='h4'>API Users Information</Typography>
            <TextField
              name='search-api-users'
              autoComplete='new-password'
              inputRef={inpuRef}
              sx={{ marginLeft: '120px', marginRight: 'auto', width: 'calc(100% - 725px)' }}
              defaultValue={search}
              onChange={onChangeSearch}
              placeholder='Search User'
              variant='outlined'
              disabled={!!error}
              InputProps={{
                sx: { fontSize: 12, height: 32 },
                startAdornment: <SearchIcon />,
                endAdornment: (
                  <IconButton onClick={onClearSearch} size='small' sx={{ opacity: search ? 1 : 0 }}>
                    <CrossIcon />
                  </IconButton>
                ),
              }}
            />
            <ButtonComponent
              marginRight='20px'
              height={32}
              width={140}
              text='Save Excel'
              onClick={onDownloadExcel}
              endIcon={<DownloadRoundedIcon fontSize='small' />}
              disabled={loadExcel || !!error}
              variant='outlined'
            />
            <ButtonComponent
              height={32}
              width={193}
              text='Add User'
              disabled={registrationOptionsLoading}
              onClick={toggleAddUserModal}
              endIcon={<ControlPointIcon fontSize='small' />}
            />
          </Grid>
        </Box>
        {loading ? (
          <TableSkeleton tHeadHeight={56.5} rowHeight={rowHeight - 1} />
        ) : error ? (
          <Box sx={{ paddingX: 4, paddingTop: 2, paddingBottom: 1 }}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        ) : data.length ? (
          <Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 4 }}>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'email'} onClick={onChangeSort('email')}>
                      <Typography variant='caption' color={sortBy === 'email' ? 'text.primary' : 'text.secondary'}>
                        USER & COMPANY
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'type'} onClick={onChangeSort('type')}>
                      <Typography variant='caption' color='text.secondary'>
                        SEGMENT
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'environment'} onClick={onChangeSort('environment')}>
                      <Typography variant='caption' color='text.secondary'>
                        TYPE
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'accountBalance'} onClick={onChangeSort('accountBalance')}>
                      <Typography variant='caption' color='text.secondary'>
                        BALANCE
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'creationDate'} onClick={onChangeSort('creationDate')}>
                      <Typography variant='caption' color='text.secondary'>
                        CREATED
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'lastActivityDate'} onClick={onChangeSort('lastActivityDate')}>
                      <Typography variant='caption' color='text.secondary'>
                        LAST ACTIVITY
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Typography variant='caption' color='text.secondary'>
                      VERIFIED
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: 128 }}>
                    <Typography variant='caption' color='text.secondary'>
                      ACTIONS
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ height: 540 }}>
                {PageContent}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (rowHeight + 1) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box sx={{ marginBottom: 3 }} />
            <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
              <Pagination shape='rounded' page={page} count={totalPages} onChange={onChangePage} size='small' color='primary' />
            </Grid>
          </Fragment>
        ) : (
          <Box sx={{ paddingX: 4, paddingTop: 2, paddingBottom: 1 }}>
            <Alert severity='info'>No Info</Alert>
          </Box>
        )}
      </Card>
      <AddUserModal open={addUserModalOpen} onClose={toggleAddUserModal} updateUsersData={getUsers} key={String(addUserModalOpen)} editUserData={editUserData} />
    </Fragment>
  );
};

const Label = ({ environment }) => {
  const {
    palette: {
      text: { green, secondary },
      chart: { orange },
    },
  } = useTheme();
  const LabelsMap = {
    TRIAL: { color: orange, text: 'Trial' },
    CLIENT: { color: green, text: 'Client' },
    INTERNAL: { color: secondary, text: 'Internal' },
    null: {
      color: orange,
      text: 'Trial',
    },
    undefined: {
      color: orange,
      text: 'Trial',
    },
  };

  return (
    <Grid sx={{ width: 60, height: 20, background: LabelsMap[environment].color + '16', borderRadius: 0.5 }} container alignItems='center' justifyContent='center'>
      <Typography variant='caption' color={LabelsMap[environment].color}>
        {LabelsMap[environment].text}
      </Typography>
    </Grid>
  );
};

export const VerificationStatus = ({ status }) => {
  const {
    palette: {
      text: { secondary, green, red },
    },
  } = useTheme();

  const LabelsMap = {
    true: { color: green, icon: <CheckIcon />, description: 'Account Verified' },
    false: { color: red, icon: <CrossIcon />, description: 'The user did not pass verification via email' },
    null: { color: secondary, icon: <ClockIcon />, description: 'Unknown' },
    undefined: { color: secondary, icon: <ClockIcon />, description: 'Unknown' },
  };

  return (
    <Tooltip
      title={
        <Typography variant='body2' color='text.dark'>
          {LabelsMap[status].description}
        </Typography>
      }
      arrow
      placement='top'
    >
      <Grid sx={{ width: 20, height: 20, background: LabelsMap[status].color + '16', borderRadius: '50%' }} container alignItems='center' justifyContent='center'>
        {LabelsMap[status].icon}
      </Grid>
    </Tooltip>
  );
};
