import { Button as MaterialButton } from '@mui/material';

export const ButtonComponent = ({
  text,
  endIcon,
  width = 236,
  height = 36,
  variant = 'contained',
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  onClick,
  color = 'primary',
  disabled = false,
}) => {
  return (
    <MaterialButton
      sx={{
        width,
        height,
        fontSize: 12,
        justifyContent: endIcon ? 'space-between' : 'center',
        paddingX: 3,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
        letterSpacing: 'normal',
      }}
      {...{ color, variant, endIcon, onClick, disabled }}
    >
      {text}
    </MaterialButton>
  );
};
