import { useState, useEffect, Fragment } from 'react';
import { Card, Typography, Table, TableHead, TableRow, TableCell, TableBody, Grid, Button, Alert, Box } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import moment from 'moment';

import { CreatePromoCode } from './createPromoCode';
import { DeletePromoCodeModal } from './deletePromoCode';
import { AdminApi } from 'api';
import { usePageTitle } from 'helpers/hooks';
import { ButtonComponent } from 'components/ui';
import { CrossIcon } from 'components';
import { squareBtnStyle } from '../dashboard/trialUnitsOrders';
import { TableSkeleton } from 'components/common/skeletons';

export const PromoCodes = () => {
  usePageTitle('Datai API Panel · Promo Codes');
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [removeName, setRemoveName] = useState('');

  const toggleModal = () => setModalOpen(!modalOpen);

  const onConfirmRemove = (str) => () => setRemoveName(str);

  const renderCodes = () =>
    data.map(({ creationDate, description, expirationDate, name, trialPeriod }, index) => (
      <TableRow key={'row-' + index + Math.random() + creationDate} sx={{ height: 57 }}>
        <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
          <Typography variant='body1'>{name || '-'}</Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.secondary'>
            {creationDate ? moment(creationDate).format('DD.MM.YY,HH:mm') : '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.secondary'>
            {expirationDate ? moment(expirationDate).format('DD.MM.YY,HH:mm') : '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1'>{trialPeriod || '-'}</Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1'>{description || '-'}</Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }} align='right'>
          <Button size='small' variant='outlined' color='error' sx={{ ...squareBtnStyle }} onClick={onConfirmRemove(name)}>
            <CrossIcon />
          </Button>
        </TableCell>
      </TableRow>
    ));

  const getPromoCodes = () => {
    setError('');
    setLoading(true);
    AdminApi.getPromoCodes()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPromoCodes();
  }, []);

  return (
    <Fragment>
      <Card sx={{ paddingTop: 3, paddingBottom: 6 }} variant='outlined'>
        <Grid sx={{ marginBottom: 2, paddingX: 4 }} container justifyContent='space-between'>
          <Typography variant='h4'>Datai Promo Codes</Typography>
          <ButtonComponent disabled={loading || error} height={32} width={193} text='Add Promo Code' onClick={toggleModal} endIcon={<ControlPointIcon fontSize='small' />} />
        </Grid>
        {loading ? (
          <TableSkeleton tHeadHeight={56.5} rowHeight={57} />
        ) : error ? (
          <Box sx={{ paddingX: 4 }}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        ) : !data.length ? (
          <Box sx={{ paddingX: 4 }}>
            <Alert severity='info'>No Info</Alert>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4, width: 130 }}>
                  <Typography variant='caption' color='text.secondary'>
                    Name
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 160 }}>
                  <Typography variant='caption' color='text.secondary'>
                    Creation Date
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 160 }}>
                  <Typography variant='caption' color='text.secondary'>
                    Expiration Date
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 110 }}>
                  <Typography variant='caption' color='text.secondary'>
                    Trial Period
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    Description
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ width: 100 }}>
                  <Typography variant='caption' color='text.secondary'>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderCodes()}</TableBody>
          </Table>
        )}
      </Card>
      <CreatePromoCode key={`create-Datai-promocode-${String(modalOpen)}`} updatePromoCodes={getPromoCodes} open={modalOpen} onCloseModal={toggleModal} />
      <DeletePromoCodeModal key={`delete-Datai-promocode-${removeName}`} name={removeName} updateData={getPromoCodes} open={!!removeName} setRemoveName={setRemoveName} />
    </Fragment>
  );
};
