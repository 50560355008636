import { Fragment } from 'react';
import { Card, Typography, Grid, Alert, Avatar, AvatarGroup, Divider, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import moment from 'moment';
import makeBlock from 'ethereum-blockies-base64';

import { GeneralInfoSkeleton } from 'components/common/skeletons';

export const Bundles = ({ loading = true, data = [] }) => {
  const renderBundles = () => {
    if (data.length) {
      return data.map(({ name, id, creationDate, wallets }, index, { length }) => (
        <Accordion key={id + creationDate + name} variant='outlined' sx={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
          <AccordionSummary sx={{ padding: 0, margin: 0 }} expandIcon={<ExpandMoreRoundedIcon color='primary' />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container justifyContent='space-between' key={id + creationDate + name}>
              <Grid flexDirection='column' sx={{ width: '20%' }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Name
                </Typography>
                <Typography variant='body1'>{name}</Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ width: '20%' }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Created
                </Typography>
                <Typography variant='body1'>{moment(creationDate).format('DD.MM.YY , HH:mm')}</Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ width: '60%' }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Wallets
                </Typography>
                <Grid alignItems='center' container>
                  <AvatarGroup sx={{ width: 'fit-content' }} max={10}>
                    {wallets.map(({ address, color, id: walletId }) => (
                      <Avatar key={walletId + color} alt={address} src={makeBlock(address)} sx={{ width: 21, height: 21 }} />
                    ))}
                  </AvatarGroup>
                  <Typography sx={{ marginLeft: 1 }} variant='body1'>
                    {wallets.length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index < length - 1 ? <Divider sx={{ marginY: 2 }} /> : null}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, paddingBottom: 2 }}>
            <Typography>Bundle Wallets</Typography>
            <Grid container justifyContent='space-between' key={id + creationDate + name} sx={{ width: 'calc(100% - 24px)', marginBottom: 3 }}>
              <Grid flexDirection='column' sx={{ width: '40%' }}>
                <Typography variant='caption' color='text.secondary'>
                  Address
                </Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ width: '20%' }}>
                <Typography variant='caption' color='text.secondary'>
                  Color
                </Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ width: '20%' }}>
                <Typography variant='caption' color='text.secondary'>
                  Name
                </Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ width: '20%' }}>
                <Typography variant='caption' color='text.secondary'>
                  Creation Date
                </Typography>
              </Grid>
            </Grid>
            {wallets.map(({ name, id, creationDate, address, color }, index, { length }) => (
              <Fragment key={id + creationDate + name}>
                <Grid container justifyContent='space-between' key={id + creationDate + name} sx={{ width: 'calc(100% - 24px)' }}>
                  <Grid flexDirection='column' sx={{ width: '40%' }}>
                    <Grid container alignItems='center'>
                      <Avatar alt={address} src={makeBlock(address)} sx={{ width: 21, height: 21, marginRight: 1 }} />
                      <Typography variant='body1'>{address}</Typography>
                    </Grid>
                  </Grid>
                  <Grid flexDirection='column' sx={{ width: '20%' }}>
                    {color ? <Box sx={{ width: 21, height: 21, backgroundColor: color }} /> : null}
                  </Grid>
                  <Grid flexDirection='column' sx={{ width: '20%' }}>
                    <Typography variant='body1'>{name || '-'}</Typography>
                  </Grid>
                  <Grid flexDirection='column' sx={{ width: '20%' }}>
                    <Typography variant='body1'>{moment(creationDate).format('DD.MM.YY , HH:mm')}</Typography>
                  </Grid>
                </Grid>
                {index < length - 1 ? <Divider sx={{ marginY: 2 }} /> : null}
              </Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      ));
    }
    return <Alert severity='info'>No Info</Alert>;
  };

  if (loading) return <GeneralInfoSkeleton />;

  return (
    <Card sx={{ marginBottom: 3, paddingTop: 3, paddingBottom: 1, paddingX: 4 }} variant='outlined'>
      <Typography variant='h4'>Bundles</Typography>
      {renderBundles()}
    </Card>
  );
};
