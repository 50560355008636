import { Fragment, useState, useEffect } from 'react';
import { Card, Divider, Grid, Typography, Skeleton, Alert, Link, IconButton, useTheme } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Code, ShoppingCart, Copy } from 'components/icons';
import { Notification } from 'components';
import { ButtonComponent } from 'components/ui';
import { numberWithCommas } from 'helpers';
import { UserApi } from 'api';
import { RequestTrialUnitsModal } from 'pages/user/dashboard/trialUnitsModal';

import { setResetApiKeyModalOpen } from 'store/slices/bufer';
import { setUnitsLimitModalOpen } from 'store/slices/bufer';
import { paths } from 'consts';

export const MainInfo = () => {
  const {
    text: { light },
    background: { header },
  } = useTheme().palette;
  const dispatch = useDispatch();
  const { email, accessKey, isTester, unitsLimit, loading: userInfoLoading, error: userInfoError } = useSelector((s) => s.userBaseInfo);
  const navigate = useNavigate();
  const [{ accountBalance, accountBalanceTrial, loading: balanceLoading, error: balanceError }, setAccountState] = useState({
    accountBalance: 0,
    accountBalanceTrial: 0,
    loading: true,
    error: '',
  });
  const [trialModalOpen, setTrialModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const error = userInfoError || balanceError;

  const toggleTrialModal = () => setTrialModalOpen(!trialModalOpen);

  const onPurchaseUnits = () => navigate(`/${paths.user.path}/${paths.dashboard.path}/${paths.purchaseUnits.path}`);

  const onResetCopied = () => setCopied(false);

  const onCopyKey = () => navigator.clipboard.writeText(accessKey).then(() => setCopied(true));

  const onResetApiKey = () => dispatch(setResetApiKeyModalOpen(true));

  const onOpenMyAccount = () => dispatch(setUnitsLimitModalOpen(true));

  useEffect(() => {
    Promise.all([UserApi.getAccountBalance(), UserApi.getAccountBalance(true)])
      .then(([accountBalance, accountBalanceTrial]) => setAccountState({ accountBalance, accountBalanceTrial, loading: false, error: '' }))
      .catch((error) => setAccountState({ accountBalance: 0, loading: false, error }));
  }, []);

  return (
    <Fragment>
      <Card variant='outlined' sx={{ mb: '40px', borderColor: light }}>
        {error ? (
          <Grid sx={{ padding: 4 }}>
            <Alert severity='error'>{error}</Alert>
          </Grid>
        ) : (
          <Fragment key='content-info'>
            <Grid container sx={{ padding: 4 }} height={202} justifyContent='space-between'>
              <Grid container direction='column' sx={{ width: 'calc(100% - 500px)' }}>
                <Typography variant='h4' mb='8px'>
                  Account Balance
                </Typography>
                <Grid container alignItems='flex-end' sx={{ marginBottom: '8px' }}>
                  {balanceLoading ? (
                    <Typography variant='h1'>
                      <Skeleton sx={{ display: 'inline-block' }} width={200} variant='text' />
                    </Typography>
                  ) : (
                    <Fragment>
                      <Typography color={accountBalance <= unitsLimit ? 'error.main' : undefined} variant='h1' sx={{ marginRight: 1 }}>
                        {numberWithCommas(accountBalance) || 0}
                      </Typography>
                      <Typography variant='body1' sx={{ marginBottom: 1 }}>
                        units {accountBalanceTrial ? `(including ${accountBalanceTrial} trial)` : null}
                      </Typography>
                    </Fragment>
                  )}
                </Grid>
                <Typography variant='body1' color='text.secondary' mb='4px'>
                  E-mail:{' '}
                  <Typography variant='body1' component='span' color='text.primary'>
                    {userInfoLoading ? <Skeleton sx={{ display: 'inline-block' }} width={200} variant='text' /> : email}
                  </Typography>
                </Typography>
                <Typography variant='body1' color='text.secondary' mb='4px'>
                  My Datai contact:{' '}
                  <Link href='mailto:support@valktech.io'>
                    <Typography variant='body1' component='span' color='primary.main'>
                      support@valktech.io
                    </Typography>
                  </Link>
                </Typography>
                <Typography variant='body1' color='text.secondary'>
                  My low balance e-mail notification:{' '}
                  <Typography variant='body1' component='span' color='text.primary'>
                    {userInfoLoading ? (
                      <Skeleton sx={{ display: 'inline-block' }} width={90} variant='text' />
                    ) : (
                      <Fragment>
                        {unitsLimit} units{' '}
                        <IconButton size='small' onClick={onOpenMyAccount}>
                          <EditRoundedIcon fontSize='small' />
                        </IconButton>
                      </Fragment>
                    )}
                  </Typography>
                </Typography>
              </Grid>
              {userInfoLoading ? (
                <Grid container direction='column' sx={{ width: 492 }}>
                  <Typography variant='h4' color='text.secondary' mb={3}>
                    <Skeleton variant='text' width={150} />
                  </Typography>
                  <Grid container>
                    <Skeleton variant='rounded' width={236} height={36} sx={{ marginRight: '20px' }} />
                    <Skeleton variant='rounded' width={236} height={36} />
                  </Grid>
                </Grid>
              ) : isTester ? (
                <Grid container direction='column' sx={{ width: 492 }}>
                  <Typography variant='h4' color='text.secondary' mb={3}>
                    Quick Actions
                  </Typography>
                  <Grid container>
                    <ButtonComponent text='Purchase Units' marginRight='20px' onClick={onPurchaseUnits} endIcon={<ShoppingCart />} />
                    <ButtonComponent text='Ask for Trial Units' onClick={toggleTrialModal} endIcon={<Code />} variant='outlined' />
                  </Grid>
                </Grid>
              ) : (
                <ButtonComponent text='Purchase Units' onClick={onPurchaseUnits} endIcon={<ShoppingCart />} />
              )}
            </Grid>
            <Divider sx={{ borderColor: light }} />
            <Grid container sx={{ padding: '15px 24px', height: 58 }} alignItems='center'>
              <Typography variant='body1' color='text.secondary' mr={2}>
                My API Key:
              </Typography>
              {userInfoLoading ? (
                <Skeleton sx={{ width: 290, height: 30 }} variant='rounded' />
              ) : (
                <Grid
                  container
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ width: 290, height: 30, borderRadius: 1, cursor: 'pointer', bgcolor: header, border: '1px solid', borderColor: light, padding: '6px 14px' }}
                  onClick={onCopyKey}
                >
                  <Typography variant='body1' maxWidth='calc(100% - 20px)' noWrap>
                    {accessKey}
                  </Typography>
                  <Copy />
                </Grid>
              )}
              <ButtonComponent text='Reset API Key' color='primary' marginLeft='auto' variant='outlined' width={140} height={30} onClick={onResetApiKey} />
            </Grid>
          </Fragment>
        )}
      </Card>
      <Notification open={copied} onClose={onResetCopied} message='API key copied to clipboard' />
      <RequestTrialUnitsModal key={'modal-trial-' + String(trialModalOpen)} open={trialModalOpen} onClose={toggleTrialModal} />
    </Fragment>
  );
};
