import { Fragment, useState } from 'react';
import { Typography, Grid, MenuItem, Avatar, Badge, IconButton, Menu, Tooltip, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';

import { UserIcon, DoorOut, ChewronRightIcon } from 'components';
import { setMyApiKeyModalOpen, setMyAccountModalOpen } from 'store/slices/bufer';
import { resetStore } from 'store/helpers';
import { paths } from 'consts';

export const UserMenu = ({ isAdmin = false }) => {
  const email = useSelector((s) => s.userBaseInfo.email);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // account icon
  const {
    palette: { primary, common, error, text },
  } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOpenMyApiKey = () => {
    dispatch(setMyApiKeyModalOpen(true));
    handleClose();
  };

  const onOpenMyAccount = () => {
    dispatch(setMyAccountModalOpen(true));
    handleClose();
  };

  const onLogout = () => {
    resetStore();
    navigate(`/${paths.auth.path}`, { replace: true });
  };

  return (
    <Grid container width='fit-content' alignItems='center' mr='-5px'>
      <Typography variant='body1' sx={{ fontWeight: 500 }} color={text.light}>
        {email}
      </Typography>

      <Fragment>
        <Tooltip
          title={
            <Typography variant='body2' color='text.dark'>
              User Menu
            </Typography>
          }
          arrow
        >
          <IconButton
            sx={{ marginLeft: 1 }}
            onClick={handleClick}
            size='small'
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge
              overlap='circular'
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={
                false ? (
                  <Avatar sx={{ bgcolor: '#F5AC37', width: 14, height: 14 }}>
                    <NotificationsRoundedIcon fontSize='small' sx={{ width: 8, height: 8 }} />
                  </Avatar>
                ) : null
              }
            >
              <Avatar sx={{ bgcolor: '#182332', width: 28, height: 28 }}>
                <UserIcon strokeWidth={1} color={primary.contrastText} />
              </Avatar>
            </Badge>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              width: 260,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem sx={{ height: 50, paddingX: 4, justifyContent: 'space-between', color: primary.main }} onClick={onOpenMyApiKey}>
            My API Key
            <ChewronRightIcon size={14} color={primary.main} />
          </MenuItem>
          {isAdmin ? null : (
            <MenuItem sx={{ height: 50, paddingX: 4, justifyContent: 'space-between', color: common.black }} onClick={onOpenMyAccount}>
              About Me
              <ChewronRightIcon size={14} color={primary.main} />
            </MenuItem>
          )}
          <MenuItem sx={{ height: 50, paddingX: 4, justifyContent: 'space-between', color: error.main }} onClick={onLogout}>
            Log Out
            <DoorOut color={error.main} />
          </MenuItem>
        </Menu>
      </Fragment>
    </Grid>
  );
};
