import { useTheme } from '@mui/material/styles';

export const Coins = ({ isActive = false }) => {
  const {
    palette: {
      primary: { accent_main },
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg style={{ marginRight: 10 }} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_225_1853)'>
        <path
          d='M0 11.0824V12.25C0 13.2152 2.35156 14 5.25 14C8.14844 14 10.5 13.2152 10.5 12.25V11.0824C9.3707 11.8781 7.30625 12.25 5.25 12.25C3.19375 12.25 1.1293 11.8781 0 11.0824ZM8.75 3.5C11.6484 3.5 14 2.71523 14 1.75C14 0.784766 11.6484 0 8.75 0C5.85156 0 3.5 0.784766 3.5 1.75C3.5 2.71523 5.85156 3.5 8.75 3.5ZM0 8.21406V9.625C0 10.5902 2.35156 11.375 5.25 11.375C8.14844 11.375 10.5 10.5902 10.5 9.625V8.21406C9.3707 9.14375 7.30352 9.625 5.25 9.625C3.19648 9.625 1.1293 9.14375 0 8.21406ZM11.375 8.51484C12.9418 8.21133 14 7.64805 14 7V5.83242C13.3656 6.28086 12.4332 6.58711 11.375 6.77578V8.51484ZM5.25 4.375C2.35156 4.375 0 5.35391 0 6.5625C0 7.77109 2.35156 8.75 5.25 8.75C8.14844 8.75 10.5 7.77109 10.5 6.5625C10.5 5.35391 8.14844 4.375 5.25 4.375ZM11.2465 5.91445C12.8871 5.61914 14 5.03945 14 4.375V3.20742C13.0293 3.89375 11.3613 4.26289 9.60586 4.35039C10.4125 4.74141 11.0059 5.26641 11.2465 5.91445Z'
          fill={isActive ? accent_main : secondary}
        />
      </g>
      <defs>
        <clipPath id='clip0_225_1853'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Wallet = ({ isActive = false }) => {
  const {
    palette: {
      primary: { accent_main },
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg style={{ marginRight: 10 }} xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'>
      <g clipPath='url(#clip0_749_2419)'>
        <path
          d='M11.3094 3H2.375C2.16781 3 2 2.83219 2 2.625C2 2.41781 2.16781 2.25 2.375 2.25H11.375C11.5822 2.25 11.75 2.08219 11.75 1.875C11.75 1.25367 11.2463 0.75 10.625 0.75H2C1.17148 0.75 0.5 1.42148 0.5 2.25V9.75C0.5 10.5785 1.17148 11.25 2 11.25H11.3094C11.9661 11.25 12.5 10.7454 12.5 10.125V4.125C12.5 3.50461 11.9661 3 11.3094 3ZM10.25 7.875C9.83586 7.875 9.5 7.53914 9.5 7.125C9.5 6.71086 9.83586 6.375 10.25 6.375C10.6641 6.375 11 6.71086 11 7.125C11 7.53914 10.6641 7.875 10.25 7.875Z'
          fill={isActive ? accent_main : secondary}
        />
      </g>
      <defs>
        <clipPath id='clip0_749_2419'>
          <rect width='12' height='12' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChartPie = ({ isActive = false }) => {
  const {
    palette: {
      primary: { accent_main },
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg style={{ marginRight: 10 }} xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'>
      <g clipPath='url(#clip0_749_2425)'>
        <path
          d='M12.1425 6.70588H6.90814L10.3941 10.1918C10.5273 10.3251 10.7466 10.3359 10.8836 10.2068C11.7373 9.40257 12.3245 8.31838 12.4967 7.09963C12.5263 6.89096 12.3531 6.70588 12.1425 6.70588ZM11.7933 5.27647C11.6115 2.64132 9.50578 0.53559 6.87064 0.353825C6.66946 0.339928 6.50005 0.50956 6.50005 0.711178V5.64706H11.4362C11.6378 5.64706 11.8072 5.47765 11.7933 5.27647ZM5.44122 6.70588V1.47155C5.44122 1.26088 5.25615 1.08772 5.0477 1.11728C2.41895 1.48875 0.409607 3.7853 0.503136 6.53757C0.599313 9.36419 3.03306 11.6821 5.861 11.6466C6.97277 11.6327 8.00005 11.2745 8.84468 10.6754C9.01894 10.5518 9.03042 10.2953 8.87931 10.1442L5.44122 6.70588Z'
          fill={isActive ? accent_main : secondary}
        />
      </g>
      <defs>
        <clipPath id='clip0_749_2425'>
          <rect width='12' height='12' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const History = ({ isActive = false }) => {
  const {
    palette: {
      primary: { accent_main },
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg style={{ marginRight: 10 }} xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'>
      <g clipPath='url(#clip0_749_2431)'>
        <path
          d='M12.3125 5.98901C12.3184 9.19151 9.70672 11.8102 6.50422 11.8125C5.12105 11.8135 3.85055 11.3313 2.85214 10.5255C2.59252 10.316 2.57312 9.92688 2.80904 9.69096L3.07311 9.42689C3.27488 9.22512 3.59701 9.20304 3.82055 9.38039C4.55614 9.9641 5.48705 10.3125 6.5 10.3125C8.88371 10.3125 10.8125 8.38334 10.8125 6C10.8125 3.61629 8.88334 1.6875 6.5 1.6875C5.35592 1.6875 4.31682 2.13209 3.54528 2.85778L4.73483 4.04733C4.97108 4.28358 4.80376 4.6875 4.46968 4.6875H1.0625C0.855383 4.6875 0.6875 4.51962 0.6875 4.3125V0.90532C0.6875 0.571242 1.09142 0.403922 1.32767 0.640148L2.48483 1.7973C3.52834 0.800062 4.94262 0.1875 6.5 0.1875C9.70648 0.1875 12.3066 2.78391 12.3125 5.98901ZM8.07237 7.83551L8.3026 7.53949C8.49334 7.29427 8.44916 6.94085 8.20393 6.75014L7.25 6.00818V3.5625C7.25 3.25184 6.99816 3 6.6875 3H6.3125C6.00184 3 5.75 3.25184 5.75 3.5625V6.74182L7.28302 7.93418C7.52825 8.12489 7.88164 8.08073 8.07237 7.83551Z'
          fill={isActive ? accent_main : secondary}
        />
      </g>
      <defs>
        <clipPath id='clip0_749_2431'>
          <rect width='12' height='12' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ThList = ({ isActive = false }) => {
  const {
    palette: {
      primary: { accent_main },
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg style={{ marginRight: 10 }} xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'>
      <g clipPath='url(#clip0_749_2437)'>
        <path
          d='M3.99999 5.0625V6.9375C3.99999 7.24816 3.74816 7.5 3.43749 7.5H1.0625C0.751836 7.5 0.5 7.24816 0.5 6.9375V5.0625C0.5 4.75184 0.751836 4.5 1.0625 4.5H3.43749C3.74816 4.5 3.99999 4.75184 3.99999 5.0625ZM0.5 8.8125V10.6875C0.5 10.9982 0.751836 11.25 1.0625 11.25H3.43749C3.74816 11.25 3.99999 10.9982 3.99999 10.6875V8.8125C3.99999 8.50184 3.74816 8.25 3.43749 8.25H1.0625C0.751836 8.25 0.5 8.50184 0.5 8.8125ZM3.43749 0.75H1.0625C0.751836 0.75 0.5 1.00184 0.5 1.3125V3.1875C0.5 3.49816 0.751836 3.75 1.0625 3.75H3.43749C3.74816 3.75 3.99999 3.49816 3.99999 3.1875V1.3125C3.99999 1.00184 3.74816 0.75 3.43749 0.75ZM5.31249 11.25H11.9375C12.2482 11.25 12.5 10.9982 12.5 10.6875V8.8125C12.5 8.50184 12.2482 8.25 11.9375 8.25H5.31249C5.00183 8.25 4.74999 8.50184 4.74999 8.8125V10.6875C4.74999 10.9982 5.00183 11.25 5.31249 11.25ZM4.74999 1.3125V3.1875C4.74999 3.49816 5.00183 3.75 5.31249 3.75H11.9375C12.2482 3.75 12.5 3.49816 12.5 3.1875V1.3125C12.5 1.00184 12.2482 0.75 11.9375 0.75H5.31249C5.00183 0.75 4.74999 1.00184 4.74999 1.3125ZM5.31249 7.5H11.9375C12.2482 7.5 12.5 7.24816 12.5 6.9375V5.0625C12.5 4.75184 12.2482 4.5 11.9375 4.5H5.31249C5.00183 4.5 4.74999 4.75184 4.74999 5.0625V6.9375C4.74999 7.24816 5.00183 7.5 5.31249 7.5Z'
          fill={isActive ? accent_main : secondary}
        />
      </g>
      <defs>
        <clipPath id='clip0_749_2437'>
          <rect width='12' height='12' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};
