import ApiInstance from 'api/apiInstance';

export class AdminApi extends ApiInstance {
  static getRegistrationOptions = () => AdminApi.axiosGet(`${AdminApi.adminPath}/userRegistrationOptions`);

  static getUsers = (params, signal = null) => AdminApi.axiosGet(`${AdminApi.adminPath}/usersPageable`, params, signal);

  static registerApiUser = (data) => AdminApi.axiosPost(`${AdminApi.adminPath}/registration`, data);

  static updateApiUser = (userId, data) => AdminApi.axiosPut(`${AdminApi.adminMerlinPath}/edit-user/${userId}`, data);

  static getUsersExel = () => AdminApi.axiosGetFile(`${AdminApi.adminPath}/excel/user`);

  static getUserExel = (userId) => AdminApi.axiosGetFile(`${AdminApi.adminPath}/excel/userDetails?userId=${userId}`);

  static getAllOrders = () => AdminApi.axiosGet(`${AdminApi.adminPath}/allOrders`);

  static getOrders = (params) => AdminApi.axiosGet(`${AdminApi.adminPath}/orders-pageable`, params);

  static getTrialOrders = (params) => AdminApi.axiosGet(`${AdminApi.adminPath}/trial-orders`, params);

  static getApiUsersInvoices = (params) => AdminApi.axiosGet(`${AdminApi.adminPath}/stripe/invoices`, params);

  static setOrderStatus = (data, id, isApprove = true) =>
    isApprove ? AdminApi.axiosPost(`${AdminApi.adminPath}/approveOrder/${id}`, data) : AdminApi.axiosDelete(`${AdminApi.adminPath}/rejectOrder/${id}`);

  //get api users info
  static getUserBaseInfo = (userId) => AdminApi.axiosGet(`${AdminApi.adminPath}/baseUserInfo`, { userId });

  static getUserAccountBalance = (userId, isTrial = false) => AdminApi.axiosGet(`${AdminApi.adminPath}/accountBalance`, { userId, ...(isTrial ? { isTrial } : {}) });

  static getUserTopUpHistory = (userId) => AdminApi.axiosGet(`${AdminApi.adminPath}/topUpHistory`, { userId });

  static getUserCreditsUsage = (params) => AdminApi.axiosGet(`${AdminApi.adminPath}/usageStatistics`, params);

  static getUserMostUsedEndpoints = (userId) => AdminApi.axiosGet(`${AdminApi.adminPath}/mostUsedEndpoints`, { userId });

  //add units to api user
  static addApiUserUnits = (data) => AdminApi.axiosPost(`${AdminApi.adminPath}/addUnits`, data);

  static sendInvoiceToApiUser = (data) => AdminApi.axiosPost(`${AdminApi.adminPath}/stripe/create-invoice`, data);

  //change api user key
  static changeUserApiKey = (userId) => AdminApi.axiosPost(`${AdminApi.adminPath}/resetPassword`, { userId });

  //delete user
  static deleteApiUser = (userId) => AdminApi.axiosDelete(`${AdminApi.adminPath}/deleteApiUser?userId=${userId}`);
  //Datai users
  static getMerlinUsers = (params, signal = null) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/usersPageable`, params, signal);

  static getMerlinUser = (userId) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/userDetails`, { userId });

  static getProPlusRequests = () => AdminApi.axiosGet(`${AdminApi.adminPath}/users-wanted-to-be-pro`);

  static getEnterpriseRequests = () => AdminApi.axiosGet(`${AdminApi.adminPath}/users-wanted-to-be-enterprise`);

  static getMerlinUserBundles = (userId) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/bundle`, { userId });

  static getMerlinUserWatchlist = (userId) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/watchlist`, { userId });

  static getCopilotUserPortfolios = (userId) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/co-pilot`, { userId });

  static updateMerlinUserSubscription = (userId, params = {}) => AdminApi.axiosPut(`${AdminApi.adminPath}/subscriptionPlan/${userId}`, null, { ...params });

  static getUserUnitsUsageChart = (params) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/credits-usage`, params);

  static getUserUnitsUsageTable = (params) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/units-usage`, params);

  static getTopUps = (params) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/top-up-history`, params);

  static getUserStripeInvoiceLink = (params) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/invoice-link`, params);

  static getUserTrialRequests = (params) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/trial-requests`, params);

  static getUserEndpointStatisticChart = (params) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/endpoints-usage`, params);

  static getUserEndpointsCallHistory = (params) => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/endpoints-call-history`, params);

  static getApiUserDetails = (userId = '') => AdminApi.axiosGet(`${AdminApi.adminPath}/user`, { userId });

  static getSubscriptionOptions = () => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/subscriptionOptions`);

  static getPromoCodes = () => AdminApi.axiosGet(`${AdminApi.adminMerlinPath}/promo-codes`);

  static createPromoCode = (body) => AdminApi.axiosPost(`${AdminApi.adminMerlinPath}/promo-code`, body);

  static deletePromoCode = (name) => AdminApi.axiosDelete(`${AdminApi.adminMerlinPath}/promo-code?name=${name}`);

  static getAdminBalance = () => AdminApi.axiosGet(`${AdminApi.adminPath}/global-units-balance`);
}
