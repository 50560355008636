import { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Typography, Grid, Alert, Skeleton, useTheme } from '@mui/material';

import { Copy } from 'components/icons';
import { Notification } from 'components';
import { ButtonComponent } from 'components/ui';
import { setResetApiKeyModalOpen } from 'store/slices/bufer';

export const AdminBaseInfo = () => {
  const {
    text: { light },
    background: { header },
  } = useTheme().palette;
  const dispatch = useDispatch();
  const { email, accessKey, loading: userInfoLoading, error: userInfoError } = useSelector((s) => s.userBaseInfo);
  const [copied, setCopied] = useState(false);

  const onResetCopied = () => setCopied(false);

  const onCopyKey = () => navigator.clipboard.writeText(accessKey).then(() => setCopied(true));

  const onResetApiKey = () => dispatch(setResetApiKeyModalOpen(true));

  return (
    <Fragment>
      <Card sx={{ marginBottom: 3, paddingY: 3, paddingX: 4, borderColor: light }} variant='outlined'>
        <Grid container sx={{ marginBottom: 1 }} justifyContent='space-between'>
          <Typography variant='h4'>Welcome, Admin!</Typography>
          <ButtonComponent text='Reset API Key' color='primary' marginLeft='auto' variant='outlined' width={140} height={30} onClick={onResetApiKey} disabled={!!userInfoError} />
        </Grid>
        {userInfoError ? (
          <Alert severity='error'>{userInfoError}</Alert>
        ) : (
          <Fragment>
            <Typography variant='body1' color='text.secondary' mb={1}>
              E-mail:{' '}
              <Typography variant='body1' component='span' color='text.primary'>
                {userInfoLoading ? <Skeleton sx={{ display: 'inline-block' }} width={200} variant='text' /> : email}
              </Typography>
            </Typography>
            <Grid container alignItems='center'>
              <Typography variant='body1' color='text.secondary' mr={2}>
                My API Key:
              </Typography>
              {userInfoLoading ? (
                <Skeleton sx={{ width: 290, height: 30 }} variant='rounded' />
              ) : (
                <Grid
                  container
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ width: 290, height: 30, borderRadius: 1, cursor: 'pointer', bgcolor: header, border: '1px solid', borderColor: light, padding: '6px 14px' }}
                  onClick={onCopyKey}
                >
                  <Typography variant='body1' maxWidth='calc(100% - 20px)' noWrap>
                    {accessKey}
                  </Typography>
                  <Copy />
                </Grid>
              )}
            </Grid>
          </Fragment>
        )}
      </Card>
      <Notification open={copied} onClose={onResetCopied} message='API key copied to clipboard' />
    </Fragment>
  );
};
