import { useState, Fragment } from 'react';
import { Card, Typography, TextField, Dialog, Grow, Alert, Grid } from '@mui/material';

import { AdminApi } from 'api';
import { ButtonComponent } from 'components/ui';

export const CreatePromoCode = ({ open, onCloseModal, updatePromoCodes = console.log }) => {
  const [data, setData] = useState({ name: '', description: '', trialDaysCount: '', expirationInDays: '' });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeField = (e) => setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const disabled = data.name.length !== 10 || !data.description || +data.trialDaysCount < 1 || +data.expirationInDays < 1 || loading;

  const onCreate = () => {
    setLoading(true);
    AdminApi.createPromoCode(data)
      .then(() => {
        updatePromoCodes();
        onCloseModal();
      })
      .catch(setErrorMessage)
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 540, padding: 4, paddingBottom: 5 }}>
        <Typography sx={{ marginBottom: 2 }} variant='h4'>
          Create Promo Code
        </Typography>
        {errorMessage ? (
          <Grow in={!!errorMessage}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Grow>
        ) : (
          <Fragment>
            <Typography sx={{ marginBottom: 4 }} variant='body2' component='p' color='text.secondary'>
              Promocode Name - 10 symbols
            </Typography>

            <Grid container justifyContent='space-between' mb='20px'>
              <Grid container direction='column' width='calc(33% - 10px)'>
                <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' color='text.secondary'>
                  Promocode Name
                </Typography>
                <TextField
                  InputProps={{ sx: { fontSize: 14 } }}
                  onChange={onChangeField}
                  value={data.name}
                  placeholder='Promocode Name'
                  name='name'
                  error={data.name && data.name.length !== 10}
                  variant='outlined'
                  fullWidth
                  disabled={loading}
                />
              </Grid>
              <Grid container direction='column' width='calc(33% - 10px)'>
                <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' color='text.secondary'>
                  Trial Days Count
                </Typography>
                <TextField
                  onChange={onChangeField}
                  value={data.trialDaysCount}
                  type='number'
                  placeholder='Trial Days'
                  name='trialDaysCount'
                  InputProps={{ sx: { fontSize: 14 } }}
                  variant='outlined'
                  fullWidth
                  disabled={loading}
                />
              </Grid>
              <Grid container direction='column' width='calc(33% - 10px)'>
                <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' color='text.secondary'>
                  Expiration In Days
                </Typography>
                <TextField
                  onChange={onChangeField}
                  value={data.expirationInDays}
                  type='number'
                  name='expirationInDays'
                  placeholder='Exp. In Days'
                  InputProps={{ sx: { fontSize: 14 } }}
                  variant='outlined'
                  fullWidth
                  disabled={loading}
                />
              </Grid>
            </Grid>
            <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              Description of benefits
            </Typography>
            <TextField
              onChange={onChangeField}
              value={data.description}
              placeholder='Description of benefits'
              name='description'
              fullWidth
              InputProps={{ sx: { fontSize: 14 } }}
              variant='outlined'
            />
            <Grid container justifyContent='flex-end' sx={{ marginTop: 4 }}>
              <ButtonComponent color='primary' variant='outlined' disabled={loading} onClick={onCloseModal} text='CANCEL' width={120} marginRight='20px' />
              <ButtonComponent disabled={disabled} onClick={onCreate} text='CREATE' />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};
