import { Link } from 'react-router-dom';
import { Typography, Box, Card, Grid, useTheme } from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import { usePageTitle } from 'helpers/hooks';
import { wrapperWidth, wrapperPaddingX, wrapperPaddingY, paths } from 'consts';

export const NotFound = ({ customRedirectPath = '' }) => {
  const spacing = useTheme().spacing;

  return (
    <Box sx={{ height: '100vh', width: '100vw', backgroundColor: 'background.content' }}>
      <Box sx={{ maxWidth: `calc(${wrapperWidth}px + ${spacing(wrapperPaddingX * 2)})`, paddingX: wrapperPaddingX, paddingY: wrapperPaddingY, margin: '0 auto' }}>
        <NotFoundCard customRedirectPath={customRedirectPath} />
      </Box>
    </Box>
  );
};

export const NotFoundCard = ({ customRedirectPath = '' }) => {
  usePageTitle('Datai API Panel · Not Found');
  const {
    palette: {
      primary: { main: mainBlue },
    },
  } = useTheme();

  return (
    <Card sx={{ width: '100%', height: 500, padding: 4 }} variant='outlined'>
      <Grid container sx={{ height: '100%' }} direction='column' alignItems='center' justifyContent='center'>
        <Grid sx={{ width: 100, height: 100, borderRadius: 50, marginBottom: 2.5 }} bgcolor={`${mainBlue}16`} container alignItems='center' justifyContent='center'>
          <ErrorOutlineRoundedIcon sx={{ width: 42, height: 42 }} color='primary' />
        </Grid>
        <Typography variant='h4' mb={1.2}>
          404
        </Typography>
        <Grid sx={{ width: 333 }}>
          <Typography variant='body2' fontSize={14} textAlign='center' color='text.secondary'>
            That page doesn't exist.
          </Typography>
          <Link className='back-link' to={customRedirectPath || paths.empty.path}>
            <Typography color='primary.main' align='center'>
              Go to Main Page
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};
