import moment from 'moment';

export const createChartOptions = (grey, borderColor, black) => {
  return {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    resizeDelay: 1000,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        titleFont: {
          family: 'Space Mono',
          size: 14,
        },
        bodyFont: {
          family: 'Space Mono',
          size: 12,
        },
        titleColor: black,
        bodyColor: grey,
        borderColor,
        borderWidth: 1,
        bodySpacing: 6,
        titleMarginBottom: 12,
        cornerRadius: 4,
        backgroundColor: '#fff',
        boxWidth: 12,
        boxHeight: 12,
        boxPadding: 4,
        usePointStyle: true,
        padding: 12,
      },
    },
    scales: {
      y: {
        position: 'right',
        stacked: false,
        grid: {
          display: true,
          borderWidth: 0,
          color: borderColor,
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          display: true,
          color: grey,
          maxTicksLimit: 7,
          align: 'inner',
          padding: 10,
          font: {
            family: 'Space Mono',
            size: 12,
            weight: 400,
          },
        },
        beginAtZero: true,
      },
      x: {
        stacked: false,
        grid: {
          display: false,
          borderWidth: 0,
          color: borderColor,
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          display: true,
          color: grey,
          align: 'inner',
          padding: 12,
          maxTicksLimit: 7,
          maxRotation: 0,
          font: {
            family: 'Space Mono',
            size: 12,
            weight: 400,
          },
        },
      },
    },
  };
};

export const createChartData = (data, colors = [], datasetsMap = {}) => {
  const chartDataFormatted = {
    labels: [],
    datasets: [],
  };

  data.labels.forEach((l) => chartDataFormatted.labels.push(moment(l).format('DD/MM, ddd')));
  data.datasets
    .filter((ds) => {
      if (Object.keys(datasetsMap).length) {
        return datasetsMap[ds.label];
      }
      return ds;
    })
    .forEach((ds, index) => {
      const currentColor = colors[index] || '#' + Math.floor(Math.random() * 16777215).toString(16);
      chartDataFormatted.datasets.push({ ...ds, borderColor: currentColor, backgroundColor: currentColor, tension: 0.4, pointRadius: 0, pointHoverRadius: 2, borderWidth: 2 });
    });

  return chartDataFormatted;
};
