import { Route, Routes, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Box, Grid, Link, useTheme } from '@mui/material';
import moment from 'moment';

import { Dashboard } from 'pages/user/dashboard';
import { PurchaseUnits } from 'pages/user/purchaseUnits';
import { LiveApiTest } from 'pages/user/liveApiTest';
import { NotFoundCard } from 'pages/common/404';

import { ProtectedRoute } from 'components/protectedRoute';
import { CheckRole } from 'components/checkRole';
import { ExternalLink } from 'components';
import { ResetApiKeyModal, MyApiKeyModal, MyAccountModal, UnitsLimitModal } from 'components/common/modals';
import { UserMenu } from 'components/common/userMenu';
import { RebrandingModal } from 'components/common/rebrandingModal';

import { usePanelInitialData } from 'helpers/hooks';
import { headerHeight, footerHeight, wrapperWidth, wrapperPaddingX, wrapperPaddingY, USER_ROLES, paths } from 'consts';
import logo from 'logo-light.png';

export const UserPanel = () => {
  const { spacing, palette } = useTheme();
  const { resetApiKeyModalOpen, myApiKeyModalOpen, myAccountModalOpen, unitsLimitModalOpen } = useSelector((s) => s.bufer);

  usePanelInitialData(false);

  return (
    <ProtectedRoute>
      <CheckRole currentRoles={USER_ROLES}>
        <Grid
          sx={{ width: '100vw', height: headerHeight, backgroundColor: 'background.header', borderBottom: '1px solid', borderColor: 'border.main' }}
          container
          component='header'
          alignItems='center'
          justifyContent='center'
        >
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            sx={{ maxWidth: `calc(${wrapperWidth}px + ${spacing(wrapperPaddingX * 2)})`, paddingX: wrapperPaddingX, height: headerHeight }}
          >
            <img src={logo} alt='logo' style={{ width: 113 }} />
            <Grid sx={{ marginLeft: '42px' }} alignItems='center'>
              <NavLink to={`/${paths.user.path}/${paths.dashboard.path}`} className='nav-link'>
                <Typography variant='body1' sx={{ fontWeight: 500 }} color='text.light'>
                  {paths.dashboard.name}
                </Typography>
              </NavLink>
            </Grid>
            <Grid sx={{ marginLeft: '32px' }} alignItems='center'>
              <NavLink to={`/${paths.user.path}/${paths.liveApiTest.path}`} className='nav-link'>
                <Typography variant='body1' sx={{ fontWeight: 500 }} color='text.light'>
                  {paths.liveApiTest.name}
                </Typography>
              </NavLink>
            </Grid>
            <Link
              href='https://valk-1.gitbook.io/merlin-v2-api-documentation/reference/welcome'
              target='_blank'
              rel='noreferrer'
              underline='none'
              sx={{ marginLeft: 'auto', marginRight: '70px' }}
            >
              <Grid alignItems='center' container sx={{ width: 'fit-content' }}>
                <Typography variant='body1' sx={{ fontWeight: 500, marginRight: 1 }} color='text.light'>
                  API Docs
                </Typography>
                <ExternalLink />
              </Grid>
            </Link>
            <UserMenu />
          </Grid>
        </Grid>
        <Box sx={{ minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`, backgroundColor: 'background.content' }}>
          <Box sx={{ maxWidth: `calc(${wrapperWidth}px + ${spacing(wrapperPaddingX * 2)})`, paddingX: wrapperPaddingX, paddingY: wrapperPaddingY, margin: '0 auto' }}>
            <Routes>
              <Route path={`/${paths.dashboard.path}`} element={<Dashboard />} />
              <Route path={`/${paths.dashboard.path}/${paths.purchaseUnits.path}`} element={<PurchaseUnits />} />
              <Route path={`/${paths.liveApiTest.path}`} element={<LiveApiTest />} />
              <Route path={paths.any.path} element={<NotFoundCard customRedirectPath={`/${paths.user.path}/${paths.dashboard.path}`} />} />
            </Routes>
          </Box>
        </Box>
        <Grid
          sx={{ width: '100vw', height: footerHeight, backgroundColor: palette.background.header, borderTop: `1px solid ${palette.border.main}` }}
          container
          component='header'
          alignItems='center'
          justifyContent='center'
        >
          <Grid container alignItems='center' sx={{ maxWidth: `calc(${wrapperWidth}px + ${spacing(wrapperPaddingX * 2)})`, paddingX: wrapperPaddingX }}>
            <Typography color='text.secondary'>Datai API © All rights reserved. {moment().year()}</Typography>
          </Grid>
        </Grid>
        <ResetApiKeyModal key={'reset-api-key-modal-' + String(resetApiKeyModalOpen)} />
        <MyApiKeyModal key={'my-api-key-modal' + String(myApiKeyModalOpen)} />
        <MyAccountModal key={'my-account-modal' + String(myAccountModalOpen)} />
        <UnitsLimitModal key={'units-limit-modal' + String(unitsLimitModalOpen)} />
      </CheckRole>
      <RebrandingModal />
    </ProtectedRoute>
  );
};
