import { Fragment } from 'react';
import { Card, Typography, Grid, Alert, Avatar, AvatarGroup, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import moment from 'moment';
import makeBlock from 'ethereum-blockies-base64';

import { GeneralInfoSkeleton } from 'components/common/skeletons';

export const CopilotPortfolios = ({ loading = true, data = [] }) => {
  const renderPortfolios = () => {
    if (data.length) {
      return data.map(({ id, name, wallets, updatedAt, status, lastActive, transactionsCount, netWorth }, index, { length }) => (
        <Accordion key={id + updatedAt + name} variant='outlined' sx={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
          <AccordionSummary sx={{ padding: 0, margin: 0 }} expandIcon={<ExpandMoreRoundedIcon color='primary' />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container justifyContent='space-between'>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Name
                </Typography>
                <Typography variant='body1'>{name}</Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Wallets
                </Typography>
                <Grid alignItems='center' container>
                  <AvatarGroup sx={{ width: 'fit-content' }} max={10}>
                    {wallets.slice(0, 3).map(({ address, color, id: walletId }) => (
                      <Avatar key={walletId + color} alt={address} src={makeBlock(address)} sx={{ width: 21, height: 21 }} />
                    ))}
                  </AvatarGroup>
                  <Typography sx={{ marginLeft: 1 }} variant='body1'>
                    {wallets.length}
                  </Typography>
                </Grid>
              </Grid>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Last Activity
                </Typography>
                <Typography variant='body1'>{moment(lastActive).format('DD.MM.YY , HH:mm')}</Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Transactions
                </Typography>
                <Typography variant='body1'>{transactionsCount ? `${transactionsCount} txs` : '-'}</Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Cost
                </Typography>
                <Typography variant='body1'>{transactionsCount ? `$${parseFloat(((transactionsCount / 20) * (15 * 0.0002)).toFixed(4))}` : '-'}</Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Net Worth
                </Typography>
                <Typography variant='body1'>{netWorth ? `$${(+netWorth.toFixed()).toLocaleString('en-EN')}` : '-'}</Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Status
                </Typography>
                {status ? (
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='center'
                    sx={{ paddingX: 1.5, paddingY: 0.5, width: 'fit-content', height: '23px', borderRadius: 1 }}
                    bgcolor={`portfolioStatus.${status}`}
                  >
                    <Typography color={'text.light'} variant='caption'>
                      {status === 'READY' ? 'Ready' : status === 'LOADING' ? 'Loading' : status}
                    </Typography>
                  </Grid>
                ) : (
                  <Typography variant='body1'>-</Typography>
                )}
              </Grid>
              <Grid flexDirection='column' sx={{ flex: 1 }}>
                <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
                  Updated At
                </Typography>
                <Typography variant='body1'>{updatedAt ? moment(updatedAt).format('DD.MM.YY , HH:mm') : '-'}</Typography>
              </Grid>
            </Grid>
            {index < length - 1 ? <Divider sx={{ marginY: 2 }} /> : null}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, paddingBottom: 2 }}>
            <Typography>Portfolio Wallets</Typography>
            <Grid container justifyContent='space-between' key={id + updatedAt + name} sx={{ width: 'calc(100% - 24px)', marginBottom: 3 }}>
              <Grid flexDirection='column' sx={{ width: '70%' }}>
                <Typography variant='caption' color='text.secondary'>
                  Address
                </Typography>
              </Grid>
              <Grid flexDirection='column' sx={{ width: '30%' }}>
                <Typography variant='caption' color='text.secondary'>
                  Creation Date
                </Typography>
              </Grid>
            </Grid>
            {wallets.map(({ id, creationDate, address }, index, { length }) => (
              <Fragment key={id + creationDate}>
                <Grid container justifyContent='space-between' sx={{ width: 'calc(100% - 24px)' }}>
                  <Grid flexDirection='column' sx={{ width: '70%' }}>
                    <Grid container alignItems='center'>
                      <Avatar alt={address} src={makeBlock(address)} sx={{ width: 21, height: 21, marginRight: 1 }} />
                      <Typography variant='body1'>{address}</Typography>
                    </Grid>
                  </Grid>
                  <Grid flexDirection='column' sx={{ width: '30%' }}>
                    <Typography variant='body1'>{moment(creationDate).format('DD.MM.YY , HH:mm')}</Typography>
                  </Grid>
                </Grid>
                {index < length - 1 ? <Divider sx={{ marginY: 2 }} /> : null}
              </Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      ));
    }
    return <Alert severity='info'>No Info</Alert>;
  };

  if (loading) return <GeneralInfoSkeleton />;

  return (
    <Card sx={{ marginBottom: 3, paddingTop: 3, paddingBottom: 1, paddingX: 4 }} variant='outlined'>
      <Typography variant='h4'>Copilot - Portfolios</Typography>
      {renderPortfolios()}
    </Card>
  );
};
