import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Typography, Box, Alert, Tabs, Tab, Skeleton } from '@mui/material';

import { PriceTable } from 'components/common/priceTable';
import { PricesGroupedSkeleton } from 'components/common/skeletons';
import { CommonApi } from 'api';
import { setUserPrices, setUserPricesError, resetUserPrices } from 'store/slices/userPrices';
import { tabStyles } from 'pages/user/dashboard/tabs';

export const PricesGrouped = ({ currentUnitsPerDollar = 0, currentUnitsPerDollarError = '', currentUnitsPerDollarLoading, userId = '', marginBottom = 3 }) => {
  const dispatch = useDispatch();
  const [{ loading, error, groups }, setState] = useState({ groups: [], loading: true, error: '' });
  const [tab, setTab] = useState(0);

  const onChangeTab = (_, index) => setTab(index);

  const renderTabs = () => groups.map(({ name }, i) => <Tab key={name + i} label={name} id={`tab-${i}`} aria-controls={`tabpanel-${i}`} sx={tabStyles} />);

  const renderTabsContent = () =>
    groups.map(({ name, list }, i) => (
      <div key={name + i + '-content'} role='tabpanel' hidden={tab !== i} id={`tab-${i}`} aria-labelledby={`tabpanel-${i}`}>
        <PriceTable data={list} />
      </div>
    ));

  useEffect(() => {
    CommonApi.getGroupedPrices(userId)
      .then((res) => {
        setState({ loading: false, error: '', groups: res });
        dispatch(setUserPrices(res));
      })
      .catch((error) => {
        setState({ loading: false, error, groups: [] });
        dispatch(setUserPricesError(error));
      });

    return () => {
      if (userId) {
        dispatch(resetUserPrices());
      }
    };
  }, [dispatch, userId]);

  const priceLoading = currentUnitsPerDollarLoading;
  const priceError = currentUnitsPerDollarError;
  const price = currentUnitsPerDollar;

  return (
    <Card sx={{ paddingY: 3, marginBottom }} variant='outlined'>
      <Box sx={{ paddingX: 4, marginBottom: 3 }}>
        <Typography variant='h4' sx={{ marginBottom: 3 }}>
          Endpoints Price
        </Typography>
        <Typography variant='h4' fontSize={16} sx={{ marginBottom: 3 }}>
          {priceError ? <Alert severity='error'>{priceError}</Alert> : priceLoading ? <Skeleton variant='text' width={150} /> : `1 Unit = $${1 / price}`}
        </Typography>
      </Box>
      {loading ? (
        <PricesGroupedSkeleton />
      ) : error ? (
        <Box sx={{ paddingX: 4, paddingBottom: 1 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : (
        <Fragment key='content'>
          <Box sx={{ paddingX: 4 }}>
            <Tabs value={tab} onChange={onChangeTab} aria-label='nav-tabs' variant='scrollable' scrollButtons={false}>
              {renderTabs()}
            </Tabs>
          </Box>
          {renderTabsContent()}
        </Fragment>
      )}
    </Card>
  );
};
