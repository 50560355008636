import { Fragment, useState, useEffect, useMemo } from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Card,
  Typography,
  Grid,
  Box,
  Pagination,
  Alert,
  Button,
  TableSortLabel,
  useTheme,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import moment from 'moment';

import { TableSkeleton } from 'components/common/skeletons';
import { CrossIcon, CheckIcon, ClockIcon, Notification } from 'components';
import { ConfirmTrialModal } from 'pages/admin/dashboard/confimOrder';

import { AdminApi } from 'api';
import { numberWithCommas } from 'helpers';

const pageSize = 10;
const rowHeight = 57;

export const squareBtnStyle = { width: 20, height: 20, minWidth: 20, borderRadius: 0.5, padding: '3px', justifyContent: 'center' };

export const TrialUnitsOrders = () => {
  const {
    palette: {
      text: { light },
    },
  } = useTheme();

  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('DESC');

  const [currentWaitId, setCurrentWaitId] = useState('');
  const [confirmData, setConfirmData] = useState(null);
  const [actionError, setActionError] = useState('');

  const createWaitId = (...args) => args.join('-');

  const onCloseError = () => setActionError('');

  const onSetConfirmData = (obj) => () => setConfirmData(obj);

  const onSetOrderStatus = (email, date, id, userId, units, isApprove) => () => {
    setCurrentWaitId(createWaitId(date, id, userId));
    AdminApi.setOrderStatus({ email, units, isTrial: true, usd: 0 }, id, isApprove)
      .then(() => getTrialOrders(page, sortBy, sortOrder))
      .catch(setActionError)
      .finally(() => setCurrentWaitId(''));
  };

  const onChangeSort = (sortKey) => () => {
    if (sortBy === sortKey) setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    else setSortBy(sortKey);
    setPage(1);
  };

  const PageContent = useMemo(() => {
    return data.map((order, index) => {
      const { id, userId, email, date, trialUnits, currentTrialUnits, totalTrialUnits, isRejected, isApproved } = order;
      return (
        <TableRow key={'row-' + index + Math.random() + userId + id} sx={{ height: rowHeight }}>
          <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
            <Typography variant='body1' color='text.dark'>
              {email}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1' color='text.secondary'>
              {date ? moment(date).format('DD.MM.YYYYY hh:mm A') : '-'}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1'>{numberWithCommas(currentTrialUnits)}</Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1'>{numberWithCommas(totalTrialUnits)}</Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            <Typography variant='body1'>{numberWithCommas(trialUnits)}</Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row' }}>
            {createWaitId(date, id, userId) === currentWaitId ? (
              <CircularProgress size={20} />
            ) : isApproved || isRejected ? (
              <RequestStatus status={isApproved ? 'isApproved' : isRejected ? 'isRejected' : null} />
            ) : (
              <Grid container flexWrap='nowrap'>
                <Tooltip
                  title={
                    <Typography variant='body2' color='text.dark'>
                      Approve Request
                    </Typography>
                  }
                  arrow
                  placement='top'
                >
                  <Button
                    size='small'
                    variant='contained'
                    color='greenBtn'
                    sx={{ ...squareBtnStyle, marginRight: 1.5 }}
                    onClick={currentWaitId ? undefined : onSetConfirmData({ email, date, id, userId, isApprove: true })}
                  >
                    <CheckIcon color={light} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography variant='body2' color='text.dark'>
                      Reject Request
                    </Typography>
                  }
                  arrow
                  placement='top'
                >
                  <Button
                    size='small'
                    variant='contained'
                    color='redBtn'
                    sx={{ ...squareBtnStyle }}
                    onClick={currentWaitId ? undefined : onSetConfirmData({ email, date, id, userId, isApprove: false })}
                  >
                    <CrossIcon color={light} />
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </TableCell>
        </TableRow>
      );
    }); // eslint-disable-next-line
  }, [data, currentWaitId]);

  const onChangePage = (_, nextPage) => setPage(nextPage);

  const emptyRows = pageSize - data.length;

  const getTrialOrders = (_page = page, _sortBy = sortBy, _sortOrder = sortOrder) => {
    setLoading(true);
    AdminApi.getTrialOrders({ page: _page - 1, pageSize, sortBy: _sortBy, sortOrder: _sortOrder })
      .then(({ content, totalPages }) => {
        setData(content);
        setTotalPages(totalPages);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTrialOrders(page, sortBy, sortOrder); // eslint-disable-next-line
  }, [page, sortBy, sortOrder]);

  return (
    <Fragment>
      <Card sx={{ paddingY: 3, marginBottom: 3 }} variant='outlined'>
        <Box sx={{ paddingX: 4 }}>
          <Typography variant='h4'>Trial Unit Requests</Typography>
        </Box>
        {loading ? (
          <TableSkeleton tHeadHeight={55.5} rowHeight={rowHeight - 1} />
        ) : error ? (
          <Box sx={{ paddingX: 4, paddingTop: 2, paddingBottom: 1 }}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        ) : data.length ? (
          <Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 4 }}>
                    <Typography variant='caption' color='text.secondary'>
                      EMAIL
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'date'} onClick={onChangeSort('date')}>
                      <Typography variant='caption' color='text.secondary'>
                        DATE
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <Typography variant='body2' color='text.dark'>
                          Number of trial units at the time of request
                        </Typography>
                      }
                      arrow
                      placement='top'
                    >
                      <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'currentTrialUnits'} onClick={onChangeSort('currentTrialUnits')}>
                        <Typography variant='caption' color='text.secondary'>
                          CURRENT TRIAL UNITS
                        </Typography>
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <Typography variant='body2' color='text.dark'>
                          Total number of trial units provided
                        </Typography>
                      }
                      arrow
                      placement='top'
                    >
                      <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'totalTrialUnits'} onClick={onChangeSort('totalTrialUnits')}>
                        <Typography variant='caption' color='text.secondary'>
                          TOTAL TRIAL UNITS
                        </Typography>
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <Typography variant='body2' color='text.dark'>
                          Number of trial units added by admin for the current request
                        </Typography>
                      }
                      arrow
                      placement='top'
                    >
                      <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'trialUnits'} onClick={onChangeSort('trialUnits')}>
                        <Typography variant='caption' color='text.secondary'>
                          UNITS
                        </Typography>
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: 128 }}>
                    <Typography variant='caption' color='text.secondary'>
                      ACTIONS/STATUS
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ height: 540 }}>
                {PageContent}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (rowHeight + 1) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box sx={{ marginBottom: 3 }} />
            <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
              <Pagination shape='rounded' page={page} count={totalPages} onChange={onChangePage} size='small' color='primary' />
            </Grid>
          </Fragment>
        ) : (
          <Box sx={{ paddingX: 4, paddingTop: 2, paddingBottom: 1 }}>
            <Alert severity='info'>No Info</Alert>
          </Box>
        )}
      </Card>
      <Notification autoHideDuration={6000} key='notifications' open={!!actionError} onClose={onCloseError} message={actionError} type='error' />
      <ConfirmTrialModal
        key={`confirm-trial-request-${String(!!confirmData)}`}
        open={!!confirmData}
        confirmData={confirmData}
        onClose={onSetConfirmData(null)}
        onSetOrderStatus={onSetOrderStatus}
      />
    </Fragment>
  );
};

export const RequestStatus = ({ status }) => {
  const {
    palette: {
      text: { secondary, green, red, light },
    },
  } = useTheme();

  const LabelsMap = {
    isApproved: { color: green, icon: <CheckIcon color={light} />, description: 'Request Approved' },
    isRejected: { color: red, icon: <CrossIcon color={light} />, description: 'Request Rejected' },
    null: { color: secondary, icon: <ClockIcon color={light} />, description: 'Unknown' },
  };

  return (
    <Tooltip
      title={
        <Typography variant='body2' color='text.dark'>
          {LabelsMap[status].description}
        </Typography>
      }
      arrow
      placement='top'
    >
      <Grid sx={{ width: 20, height: 20, background: LabelsMap[status].color + '16', borderRadius: '50%' }} container alignItems='center' justifyContent='center'>
        {LabelsMap[status].icon}
      </Grid>
    </Tooltip>
  );
};
