import { useState, useMemo, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Alert, Grow, TextField, Card, Typography, InputAdornment, IconButton, Grid, Box, Dialog, useTheme, FormControl } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';

import { login } from 'store/slices/user';
import { loginLocalStorageName, emailPathern, paths } from 'consts';
import { UserApi } from 'api';
import { usePageTitle } from 'helpers/hooks';
import { StarIcon, CloseCircleIcon } from 'components';
import { ButtonComponent } from 'components/ui';
import { ChewronRightIcon } from 'components/icons';

export const Login = () => {
  const {
    palette: {
      text: { secondary, light },
      primary: { main, gray },
      border: { main: borderColor },
    },
  } = useTheme();
  usePageTitle(`Datai API Panel · ${paths.login.name}`);
  const [searchParams] = useSearchParams();
  const preFilledEmail = searchParams.get('sign-up');
  const dispatch = useDispatch();
  const [{ email, apiKey }, setLoginData] = useState({ email: '', apiKey: '' });
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrroMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [registerEmail, setRegisterEmail] = useState((preFilledEmail || '').match(emailPathern) ? preFilledEmail : '');
  const [registerErrorMessage, setRegisterErrroMessage] = useState('');
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [captchaModal, setCaptchaModal] = useState(false);

  const toggleRegisterSuccess = () => setRegisterSuccess(!registerSuccess);

  const toggleCaptchaModal = () => setCaptchaModal(!captchaModal);

  const onChangeRegisterEmail = (e) => setRegisterEmail(e.target.value);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onChange = ({ target: { name, value } }) => {
    errorMessage && setErrroMessage('');
    setLoginData((prev) => ({ ...prev, [name]: value }));
  };

  const onLogin = async () => {
    setWait(true);
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', apiKey);
    const data = await UserApi.login(formData).catch((e) => {
      setWait(false);
      setErrroMessage(e);
    });
    if (data) {
      const { first: apiKeyCrypted, second: role } = data;
      dispatch(login({ role, apiKeyCrypted }));
      localStorage.setItem(loginLocalStorageName, JSON.stringify({ role, apiKeyCrypted }));
    }
  };

  const onSignUp = async (captchaValue) => {
    setWait(true);
    const response = await UserApi.signUp({ email: registerEmail, role: 'API_USER' }, captchaValue)
      .catch(setRegisterErrroMessage)
      .finally(() => setWait(false));
    if (response) {
      toggleRegisterSuccess();
      setRegisterEmail('');
    }
  };

  const onEnterPress = (e) => e.key === 'Enter' && onLogin();

  const onChangeCaptcha = (value) => {
    onSignUp(value);
    toggleCaptchaModal();
  };

  const registerEmailValid = useMemo(() => {
    return registerEmail.match(emailPathern);
  }, [registerEmail]);

  const emailValid = useMemo(() => {
    return email.match(emailPathern);
  }, [email]);

  const invalidEmailError = !!(email && !emailValid);
  const invalidRegisterEmailError = !!(registerEmail && !registerEmailValid);

  return (
    <Fragment>
      <Typography variant='h3' color='text.primary' align='center' sx={{ marginBottom: '16px', textTransform: 'uppercase', fontSize: 18 }}>
        Streamline blockchain data processing with business-ready & comprehensive data
      </Typography>
      <Typography variant='body1' color='text.secondary' align='center' sx={{ width: 555, marginBottom: '32px' }}>
        Wallet on-chain Transactions, DeFi Positions analytics, Token Balances, LP Token Pricing
      </Typography>
      <Card variant='outlined' sx={{ borderColor: light }}>
        <Grid container flexWrap='nowrap'>
          <Box sx={{ padding: '30px 30px 40px', width: '400px' }}>
            <Typography variant='h3' sx={{ marginBottom: '20px', textTransform: 'uppercase', fontSize: 18 }}>
              Login
            </Typography>
            <Typography variant='body2' component='p' sx={{ marginBottom: '32px' }} color='text.secondary'>
              Please fill in your e-mail address and API key to log in
            </Typography>
            {errorMessage ? (
              <Grow in={!!errorMessage}>
                <Alert sx={{ marginBottom: 3 }} severity='error'>
                  {errorMessage}
                </Alert>
              </Grow>
            ) : null}
            <TextField
              autoFocus
              sx={{ marginBottom: invalidEmailError ? '8px' : '28px' }}
              InputLabelProps={{ shrink: true }}
              InputProps={{ sx: { fontSize: 14 } }}
              placeholder='Enter your e-mail'
              value={email}
              onChange={onChange}
              onKeyDown={onEnterPress}
              name='email'
              label='E-mail'
              fullWidth={true}
              disabled={wait}
              error={invalidEmailError}
              helperText={invalidEmailError ? `Email address ${email} is invalid` : ''}
            />
            <FormControl fullWidth sx={{ marginBottom: '32px' }}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton size='small' aria-label='toggle password visibility' onClick={toggleShowPassword} onMouseDown={(e) => e.preventDefault()} edge='end'>
                        {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: { fontSize: 14 },
                }}
                InputLabelProps={{ shrink: true }}
                id='api-key-input'
                value={apiKey}
                onChange={onChange}
                onKeyDown={onEnterPress}
                name='apiKey'
                label='API-key'
                placeholder='Enter your api key'
                type={showPassword ? 'text' : 'password'}
                fullWidth={true}
                disabled={wait}
              />
            </FormControl>
            <ButtonComponent
              height={40}
              width='100%'
              text='Login'
              onClick={onLogin}
              disabled={wait || !email || !apiKey || !emailValid}
              endIcon={<ChewronRightIcon color={(wait || !email || !apiKey || !emailValid) && secondary} />}
            />
          </Box>
          <Box sx={{ padding: '30px 30px 40px', width: '420px', backgroundColor: 'background.content', borderLeft: '1px solid', borderLeftColor: light }}>
            <Typography variant='h3' sx={{ marginBottom: '20px', textTransform: 'uppercase', fontSize: 18 }}>
              Don't have an account?
            </Typography>
            <Grid
              container
              justifyContent='space-between'
              alignItems='flex-start'
              sx={{ width: '100%', height: 116, background: gray, marginBottom: '26px', border: `1px solid ${borderColor}`, paddingX: '24px', paddingY: '14px' }}
            >
              <Grid container alignItems='center' justifyContent='center' sx={{ width: '42px', height: '42px', backgroundColor: 'primary.main' }}>
                <StarIcon size={16} />
              </Grid>
              <Grid container sx={{ width: 'calc(100% - 62px)' }}>
                <Typography variant='subtitle1' sx={{ marginBottom: '8px' }}>
                  4000 Free Units
                </Typography>
                <Typography variant='body2' component='p' color='text.secondary' sx={{ lineHeight: '15px' }}>
                  All you have to do is sign up! And access your own Client Panel with all information necessary to get you started.
                </Typography>
              </Grid>
            </Grid>
            {registerErrorMessage ? (
              <Grow in={!!registerErrorMessage}>
                <Alert sx={{ marginBottom: 3 }} severity='error'>
                  {registerErrorMessage}
                </Alert>
              </Grow>
            ) : null}
            <TextField
              sx={{ marginBottom: invalidRegisterEmailError ? '12px' : '32px' }}
              InputLabelProps={{ shrink: true }}
              InputProps={{ sx: { fontSize: 14 } }}
              placeholder='Enter your e-mail'
              value={registerEmail}
              onChange={onChangeRegisterEmail}
              name='register-email'
              label='E-mail'
              fullWidth={true}
              disabled={wait}
              error={invalidRegisterEmailError}
              helperText={invalidRegisterEmailError ? `Email address ${registerEmail} is invalid` : ''}
            />
            <ButtonComponent
              text='Sign Up'
              onClick={toggleCaptchaModal}
              disabled={wait || !registerEmail || !registerEmailValid}
              endIcon={<ChewronRightIcon color={wait || !registerEmail || !registerEmailValid ? secondary : main} />}
              variant='outlined'
              width='100%'
              height={40}
            />
          </Box>
        </Grid>
      </Card>
      {/* Success modal  */}
      <Dialog open={registerSuccess} onClose={toggleRegisterSuccess} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <Card sx={{ width: 366, paddingX: 5, paddingTop: 4, paddingBottom: 5 }}>
          <Grid container justifyContent='space-between' mb={2}>
            <Typography variant='h4'>Check your e-mail</Typography>
            <Box sx={{ cursor: 'pointer' }} onClick={toggleRegisterSuccess}>
              <CloseCircleIcon />
            </Box>
          </Grid>
          <Typography variant='body2' component='p' color='text.secondary' mb='20px'>
            Thank you for signing up! Please check your e-mail to finish your sign up. You can close this window.
          </Typography>
          <ButtonComponent text='OK, CLOSE' onClick={toggleRegisterSuccess} width={153} variant='outlined' />
        </Card>
      </Dialog>
      {/* Captcha modal  */}
      <Dialog open={captchaModal} onClose={toggleCaptchaModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <Card sx={{ padding: 4 }}>
          <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_ID} onChange={onChangeCaptcha} />
        </Card>
      </Dialog>
    </Fragment>
  );
};
