import { Fragment, useState, useMemo } from 'react';
import { Table, TableHead, TableCell, TableRow, TableBody, Typography, Grid, Box, Pagination, Tooltip, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

import { Info } from 'components/icons';
import { numberWithCommas } from 'helpers';

export const PriceTable = ({ data = [] }) => {
  const portion = 15;
  const [page, setPage] = useState(0);
  const pagesCount = useMemo(() => Math.ceil(data.length / portion), [data]);

  const PageContent = useMemo(() => {
    const startIndex = page * portion;
    const endIndex = startIndex + portion;
    return data.slice(startIndex, endIndex).map((endpoint, index) => <EndpointRow endpoint={endpoint} key={'row-' + index + Math.random()} />);
  }, [page, data]);

  const onChangePage = (_, nextPage) => setPage(nextPage - 1);

  const emptyRows = Math.max(0, (1 + page) * portion - data.length);

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingLeft: 4 }}>
              <Typography variant='caption' color='text.secondary'>
                ENDPOINT
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '20%' }}>
              <Typography variant='caption' color='text.secondary'>
                UNITS PER CALL
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '20%' }}>
              <Typography variant='caption' color='text.secondary'>
                PRICE ($)
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ height: 540 }}>
          {PageContent}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 54 * emptyRows,
              }}
            >
              <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box sx={{ marginBottom: 3 }} />
      <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
        <Pagination shape='rounded' count={pagesCount} onChange={onChangePage} size='small' color='primary' />
      </Grid>
    </Fragment>
  );
};

export const EndpointRow = ({ endpoint: { cost, endpoint, credits, route } }) => {
  const [routeCopied, setRouteCopied] = useState(false);

  const onCopyRoute = () => {
    navigator.clipboard.writeText(route).then(() => {
      setRouteCopied(true);
      setTimeout(() => {
        setRouteCopied(false);
      }, 1500);
    });
  };

  return (
    <TableRow sx={{ height: 53 }}>
      <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4, '&:hover': { '.copy-btn': { opacity: 1 } } } }}>
        <Grid container alignItems='center' sx={{ width: 'fit-content' }}>
          <Tooltip
            title={
              <Typography variant='body2' color='text.dark'>
                {route || '-'}
              </Typography>
            }
            arrow
            placement='top'
            leaveDelay={0}
            enterDelay={0}
          >
            <Grid container alignItems='center' sx={{ width: 'fit-content', cursor: 'pointer' }}>
              <Typography variant='body1' color='text.dark' sx={{ marginRight: 1 }}>
                {endpoint}
              </Typography>
              <Info />
            </Grid>
          </Tooltip>
          {route ? (
            <IconButton
              size='small'
              color='secondary'
              onClick={onCopyRoute}
              className='copy-btn'
              sx={{ marginLeft: 1, opacity: 0, transitionProperty: 'opacity', transitionDelay: '0.1s', transitionDuration: '0.15s' }}
            >
              {routeCopied ? <LibraryAddCheckIcon fontSize='inherit' sx={{ width: 12, height: 12 }} /> : <ContentCopyIcon fontSize='inherit' sx={{ width: 12, height: 12 }} />}
            </IconButton>
          ) : null}
        </Grid>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1' color='text.dark'>
          {numberWithCommas(credits) || '-'}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1' color='text.dark'>
          ${cost}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
