import { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Typography, Grid, Button, Alert } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { BaseInfo } from 'pages/admin/merlinUserDetais/baseInfo';
import { Watchlist } from 'pages/admin/merlinUserDetais/watchlist';
import { Bundles } from 'pages/admin/merlinUserDetais/bundles';
import { CopilotPortfolios } from 'pages/admin/merlinUserDetais/copilotPortfolios';
import { ManageSubscriptionModal } from 'pages/admin/merlinUserDetais/manageSubscription';

import { AdminApi } from 'api';
import { usePageTitle } from 'helpers/hooks';
import { paths } from 'consts';

export const MerlinUserDetails = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [{ userInfo, bundles, watchlist, portfolios }, setData] = useState({ userInfo: {}, bundles: [], watchlist: [], portfolios: [] });
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  usePageTitle(`Datai API Panel · App User Details${userInfo && userInfo.email ? ` · ${userInfo.email}` : ''}`);

  const toggleModal = () => setModal(!modal);

  const onGoToUsers = () => navigate(`/${paths.admin.path}/${paths.appUsers.path}`);

  const getData = (id = userId) => {
    setLoading(true);
    Promise.all([AdminApi.getMerlinUser(id), AdminApi.getMerlinUserBundles(id), AdminApi.getMerlinUserWatchlist(id), AdminApi.getCopilotUserPortfolios(id)])
      .then(([userInfo, bundles, watchlist, portfolios]) => {
        setData({ userInfo, bundles, watchlist, portfolios });
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (userId) {
      getData(userId);
    } // eslint-disable-next-line
  }, [userId]);

  return (
    <Fragment>
      <Card variant='outlined' sx={{ paddingY: 2, paddingX: 4, marginBottom: 3 }}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Button onClick={onGoToUsers} variant='outlined' sx={{ width: 32, height: 32, minWidth: 32, padding: 0, justifyContent: 'center' }}>
            <ArrowBackIcon fontSize='small' />
          </Button>
          <Typography variant='h4' sx={{ marginLeft: 2, marginRight: 'auto' }}>
            Back to users
          </Typography>
        </Grid>
      </Card>
      {error ? (
        <Alert severity='error'>{error}</Alert>
      ) : (
        <Fragment>
          <BaseInfo {...{ loading, data: userInfo, bundles, watchlist, toggleModal, updateData: getData }} />
          <Bundles {...{ loading, data: bundles }} />
          <Watchlist {...{ loading, data: watchlist }} />
          <CopilotPortfolios {...{ loading, data: portfolios }} />
          {loading ? null : <ManageSubscriptionModal {...{ open: modal, onClose: toggleModal, updateData: getData, userInfo }} />}
        </Fragment>
      )}
    </Fragment>
  );
};
