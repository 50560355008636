import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, TextField, Alert, Grid, Typography, Card, Box, CircularProgress } from '@mui/material';

import { ButtonComponent } from 'components/ui';
import { AdminApi } from 'api';
import { paths } from 'consts';

export const DeleteUsersModal = ({ open, onClose, userId = '' }) => {
  const navigate = useNavigate();
  //current user info to check
  const [
    {
      loading,
      error,
      data: { currentEmail, currentApiKey },
    },
    setCurrentUserInfo,
  ] = useState({ loading: true, error: '', data: { currentEmail: '', currentApiKey: '' } });
  //repeat current info
  const [email, setEmail] = useState('');
  const [apiKey, setApiKey] = useState('');
  //action states
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleted, setDeleted] = useState(false);

  const onChangeEmail = ({ target: { value } }) => setEmail(value);

  const onChangeApiKey = ({ target: { value } }) => setApiKey(value);

  const onCloseModal = (_, reason) => {
    if (reason === 'backdropClick' && wait) return;
    if (deleted) {
      navigate(`/${paths.admin.path}/${paths.users.path}`);
    } else onClose();
  };

  const onDeleteUser = () => {
    setWait(true);
    AdminApi.deleteApiUser(userId)
      .then(() => setDeleted(true))
      .catch(setErrorMessage)
      .finally(() => setWait(false));
  };

  useEffect(() => {
    if (userId && open) {
      AdminApi.getUserBaseInfo(userId)
        .then(({ accessKey, email }) => {
          setCurrentUserInfo({ loading: false, error: '', data: { currentEmail: email, currentApiKey: accessKey } });
        })
        .catch((err) => setCurrentUserInfo((prev) => ({ ...prev, loading: false, error: err })));
    }
  }, [userId, open]);

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, padding: 4, paddingBottom: 5 }}>
        <Typography mb='12px' sx={{ marginBottom: 2 }} variant='h4'>
          Delete user
        </Typography>
        {loading ? (
          <Grid container justifyContent='center'>
            <CircularProgress />
          </Grid>
        ) : errorMessage || error ? (
          <Alert severity='error'>{errorMessage || error}</Alert>
        ) : deleted ? (
          <Alert severity='success'>The user has been successfully deleted. You will be redirected to the main page.</Alert>
        ) : (
          <Fragment>
            <Typography mb='20px' variant='body2' component='p' color='text.secondary'>
              To delete a user, enter their email address and click on the delete button. This action cannot be undone!
            </Typography>
            <Box mb='20px'>
              <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
                User e-mail
              </Typography>
              <TextField
                variant='outlined'
                placeholder='Type user e-mail'
                fullWidth
                value={email}
                onChange={onChangeEmail}
                error
                disabled={wait}
                InputProps={{ sx: { fontSize: 14 } }}
                sx={{ marginBottom: '12px' }}
              />

              <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
                User API key
              </Typography>
              <TextField
                variant='outlined'
                placeholder='Type user API key'
                fullWidth
                value={apiKey}
                onChange={onChangeApiKey}
                error
                disabled={wait}
                InputProps={{ sx: { fontSize: 14 } }}
              />
            </Box>
            <Grid container justifyContent='space-between'>
              <ButtonComponent color='primary' variant='outlined' disabled={wait} onClick={onClose} text='CANCEL' width={76} />
              <ButtonComponent color='primary' disabled={email !== currentEmail || apiKey !== currentApiKey || wait} onClick={onDeleteUser} text='DELETE USER' width={184} />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};
