import { useMemo, Fragment } from 'react';
import { Card, Typography, Alert, Box, Table, TableRow, TableHead, TableCell, TableBody, Tooltip, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { EndpointConsumptionSkeleton } from 'components/common/skeletons';
import { Info } from 'components/icons';
import { numberWithCommas } from 'helpers';

export const EndpointsConsumption = ({ loading, error, chartData, endpointsList, from, to, currentUnitsPerDollar, currentUnitsPerDollarError, userId }) => {
  const {
    loading: pricesLoading,
    error: pricingError,
    data: { pricesUngroupedList },
  } = useSelector((s) => s.userPrices);

  const data = useMemo(() => {
    const rows = [];
    chartData.datasets.forEach((ds) => {
      if (endpointsList[ds.label]) {
        const row = {};
        row.endpoint = ds.label;
        row.apiCals = ds.data.reduce((acc, val) => acc + val, 0);
        const endpointPrices = pricesUngroupedList[ds.label];
        if (endpointPrices) {
          const { route, credits } = endpointPrices;
          row.unitsUsed = row.apiCals * credits;
          row.cost = row.unitsUsed * (1 / currentUnitsPerDollar);
          row.route = route;
        } else {
          row.unitsUsed = null;
          row.cost = null;
          row.route = null;
        }
        rows.push(row);
      }
    });
    return rows;
  }, [chartData, endpointsList, pricesUngroupedList, currentUnitsPerDollar]);

  const renderRows = () => {
    return data.map(({ endpoint, apiCals, unitsUsed, cost, route }, index, { length }) => {
      const isLast = index === length - 1;
      return (
        <TableRow sx={{ height: 53 }} key={'row-' + endpoint + apiCals + index + Math.random()}>
          <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4, ...(isLast ? { borderBottom: 'none' } : {}) } }}>
            <Tooltip
              title={
                <Fragment>
                  <Typography variant='body1'>{route || '-'}</Typography>
                </Fragment>
              }
              arrow
              placement='top'
            >
              <Grid container alignItems='center' sx={{ width: 'fit-content', cursor: 'pointer' }}>
                <Typography variant='body1' color='text.dark' sx={{ marginRight: 1 }}>
                  {endpoint || '-'}
                </Typography>
                <Info />
              </Grid>
            </Tooltip>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row', sx: isLast ? { borderBottom: 'none' } : {} }}>
            <Typography variant='body1' color='text.linearGrey'>
              {typeof unitsUsed === 'number' ? numberWithCommas(unitsUsed) : '-'}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row', sx: isLast ? { borderBottom: 'none' } : {} }}>
            <Typography variant='body1' color='text.linearGrey'>
              {typeof apiCals === 'number' ? numberWithCommas(apiCals) : '-'}
            </Typography>
          </TableCell>
          <TableCell {...{ component: 'th', scope: 'row', sx: isLast ? { borderBottom: 'none' } : {} }}>
            <Typography variant='body1' color={cost ? 'text.primary' : 'text.linearGrey'}>
              {typeof cost === 'number' && cost ? `$${numberWithCommas(+cost.toFixed(2))}` : '-'}
            </Typography>
          </TableCell>
        </TableRow>
      );
    });
  };

  const componentLoading = loading || pricesLoading || (!currentUnitsPerDollar && !currentUnitsPerDollarError);
  const componentError = error || pricingError || currentUnitsPerDollarError;

  return (
    <Card sx={{ paddingTop: 4 }} variant='outlined'>
      <Typography variant='h4' pl={4}>
        Endpoints Consumption {!componentError && !isNaN(from) && !isNaN(to) ? `(${moment(from).format('DD.MM.YY')} - ${moment(to).format('DD.MM.YY')})` : ''}
      </Typography>
      {componentLoading ? (
        <EndpointConsumptionSkeleton />
      ) : componentError ? (
        <Box sx={{ padding: 4 }}>
          <Alert severity='error'>{componentError}</Alert>
        </Box>
      ) : data.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: 4 }}>
                <Typography variant='caption' color='text.secondary'>
                  ENDPOINT
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='caption' color='text.secondary'>
                  UNITS USED
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='caption' color='text.secondary'>
                  API CALLS
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='caption' color='text.secondary'>
                  PRICE ($)
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
      ) : (
        <Box sx={{ padding: 4 }}>
          <Alert severity='info'>No Info</Alert>
        </Box>
      )}
    </Card>
  );
};
