import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, TextField, Card, Typography, Grid, useTheme, Radio, Checkbox, Select, MenuItem, Grow, Alert, Tooltip } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isURL from 'validator/lib/isURL';

import { AdminApi } from 'api';
import { CheckBoxIcon, CheckBoxCheckedIcon } from 'components/icons';
import { radioButtonStyles } from 'pages/user/purchaseUnits/page.const';
import { ButtonComponent } from 'components/ui';

export const AddUserModal = ({ open, onClose, updateUsersData, editUserData = null }) => {
  const isEdit = !!editUserData;
  const {
    palette: {
      text: { primary: black, secondary: grey },
      border: { main: borderColor },
    },
  } = useTheme();
  const { environment, product, type } = useSelector((s) => s.registrationOptions.data);
  const [firstName, setFirstName] = useState(isEdit ? editUserData.firstName : '');
  const [lastName, setLastName] = useState(isEdit ? editUserData.lastName : '');
  const [email, setEmail] = useState(isEdit ? editUserData.email : '');
  const [userEnv, setUserEnv] = useState(isEdit ? editUserData.environment : Object.keys(environment)[0] || '');
  const [userProducts, setUserProducts] = useState(isEdit ? editUserData.products : Object.keys(product));
  const [userType, setUserType] = useState(isEdit && editUserData.type ? editUserData.type : Object.keys(type)[0] || '');
  const [userCompany, setUserCompany] = useState(isEdit && editUserData.companyName ? editUserData.companyName : '');
  const [hubspotLink, setHubspotLink] = useState(isEdit ? editUserData.hubspotLink || '' : '');
  const [unitsLimit, setUnitsLimit] = useState(isEdit ? editUserData.unitsLimit : 1000);
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrroMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const onSendRequest = async () => {
    setWait(true);

    const user = { firstName, lastName, email, companyName: userCompany, products: userProducts, type: userType, environment: userEnv, hubspotLink, unitsLimit };
    let formData = null;

    if (!isEdit) {
      formData = new FormData();
      for (const key in user) {
        formData.append(key, user[key]);
      }
    }

    const res = await (isEdit ? AdminApi.updateApiUser(editUserData.userId, user) : AdminApi.registerApiUser(user)).catch(setErrroMessage).finally(() => setWait(false));
    if (res) {
      updateUsersData();
      setSuccess(true);
    }
  };

  const onCloseModal = (_, reason) => {
    if (reason === 'backdropClick' && wait) return;
    onClose();
  };

  const onChangeEnv = (val) => () => setUserEnv(val);

  const onChangeProducts = (p) => () => {
    const _userProducts = [...userProducts];
    const index = _userProducts.indexOf(p);
    if (index !== -1) _userProducts.splice(index, 1);
    else _userProducts.push(p);
    setUserProducts(_userProducts);
  };

  const onChangeField =
    (setStateFc = console.log) =>
    ({ target: { value } }) =>
      setStateFc(value);

  const renderEnvs = () =>
    Object.entries(environment).map(([key, val]) => {
      const isActive = key === userEnv;
      return (
        <ClickableItem {...{ key, isActive, onClick: onChangeEnv(key), borderColor, width: 'calc(33% - 13px)' }}>
          <Typography variant='body1' color={isActive ? black : grey}>
            {val}
          </Typography>
          <Radio checked={isActive} value={key} name='env-radio-buttons' inputProps={{ 'aria-label': key }} sx={{ ...radioButtonStyles, top: 3, right: 6 }} />
        </ClickableItem>
      );
    });

  const renderProducts = () =>
    Object.entries(product).map(([key, val]) => {
      const isActive = userProducts.includes(key);
      return (
        <ClickableItem {...{ key, isActive, onClick: onChangeProducts(key), borderColor, paddingRight: '7px' }}>
          <Typography variant='body1' color={isActive ? black : grey}>
            {val}
          </Typography>
          <Checkbox checked={isActive} value={key} icon={<CheckBoxIcon />} checkedIcon={<CheckBoxCheckedIcon />} name='env-radio-buttons' inputProps={{ 'aria-label': key }} />
        </ClickableItem>
      );
    });

  const renderTypes = () =>
    Object.entries(type).map(([key, val]) => (
      <MenuItem value={key} key={key}>
        {val}
      </MenuItem>
    ));

  const isEmailValid = isEmail(email);
  const isLinkValid = isURL(hubspotLink);
  const isCompanyValid = isLength(userCompany, { min: 3 });
  const isProductsValid = !!userProducts.length;
  const isUnitsLimitValid = unitsLimit >= 1000 && Number.isInteger(+unitsLimit);
  const isValidUser = !!(isEmailValid && (!userCompany || (userCompany && isCompanyValid)) && isProductsValid && isUnitsLimitValid);

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 540, padding: 4, paddingBottom: 5 }}>
        <Typography sx={{ marginBottom: 2 }} variant='h4'>
          {isEdit ? 'Edit' : 'Add New'} API user
        </Typography>
        {errorMessage ? (
          <Grow in={!!errorMessage}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Grow>
        ) : success ? (
          <Grow in={success}>
            <Alert severity='success'>{isEdit ? 'User successfully updated.' : 'New user successfully created.'}</Alert>
          </Grow>
        ) : (
          <Fragment>
            <Typography sx={{ marginBottom: 3 }} variant='body2' component='p' color='text.secondary'>
              Please provide user data and confirm by clicking on "{isEdit ? 'Update' : 'Create'}".
            </Typography>
            <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              User E-mail
            </Typography>
            <TextField
              error={!!(email && !isEmailValid)}
              type='email'
              variant='outlined'
              placeholder='E-mail'
              fullWidth
              value={email}
              onChange={onChangeField(setEmail)}
              disabled={wait || success || isEdit}
              InputProps={{ sx: { fontSize: 14 } }}
              sx={{ marginBottom: '12px' }}
            />
            <Grid container justifyContent='space-between' mb='12px'>
              <Grid container direction='column' width='calc(50% - 10px)'>
                <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' color='text.secondary'>
                  First Name
                </Typography>
                <TextField
                  InputProps={{ sx: { fontSize: 14 } }}
                  name='firstName'
                  variant='outlined'
                  placeholder='User First Name'
                  fullWidth
                  value={firstName || ''}
                  onChange={onChangeField(setFirstName)}
                  disabled={wait}
                />
              </Grid>
              <Grid container direction='column' width='calc(50% - 10px)'>
                <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' color='text.secondary'>
                  Last Name
                </Typography>
                <TextField
                  InputProps={{ sx: { fontSize: 14 } }}
                  name='lastName'
                  variant='outlined'
                  placeholder='User Last Name'
                  fullWidth
                  value={lastName || ''}
                  onChange={onChangeField(setLastName)}
                  disabled={wait}
                />
              </Grid>
            </Grid>
            <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              Environment (one option)
            </Typography>
            <Grid container justifyContent='space-between'>
              {renderEnvs()}
            </Grid>
            <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              Product (one or both)
            </Typography>
            <Grid container justifyContent='space-between'>
              {renderProducts()}
            </Grid>
            <Grid container justifyContent='space-between' mb='12px'>
              <Grid container direction='column' sx={{ width: 'calc(50% - 10px)' }}>
                <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
                  Type
                </Typography>
                <Select value={userType} onChange={onChangeField(setUserType)} fullWidth IconComponent={ExpandMoreRoundedIcon} sx={{ fontSize: 14 }}>
                  {renderTypes()}
                </Select>
              </Grid>
              <Grid sx={{ width: 'calc(50% - 10px)' }}>
                <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
                  Company Name
                </Typography>
                <TextField
                  error={!!(userCompany && !isCompanyValid)}
                  variant='outlined'
                  placeholder='Company'
                  fullWidth
                  value={userCompany}
                  onChange={onChangeField(setUserCompany)}
                  disabled={wait || success}
                  InputProps={{ sx: { fontSize: 14 } }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between' mb={4}>
              <Grid sx={{ width: 'calc(50% - 10px)' }}>
                <Typography sx={{ marginBottom: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
                  Hubspot Link
                </Typography>
                <TextField
                  error={!!(hubspotLink && !isLinkValid)}
                  variant='outlined'
                  placeholder='Hubspot Link'
                  fullWidth
                  value={hubspotLink}
                  onChange={onChangeField(setHubspotLink)}
                  disabled={wait || success}
                  InputProps={{ sx: { fontSize: 14 } }}
                />
              </Grid>
              <Grid sx={{ width: 'calc(50% - 10px)' }}>
                <Tooltip
                  title={
                    <Typography variant='body2' color='text.dark'>
                      Low balance notification threshold. The user will receive an email when the balance drops below a specified value.
                    </Typography>
                  }
                  arrow
                  placement='top'
                  leaveDelay={0}
                  enterDelay={0}
                >
                  <Grid container mb='10px' alignItems='center' width='fit-content' sx={{ cursor: 'pointer' }}>
                    <Typography sx={{ lineHeight: '15px' }} variant='body2' component='p' color='text.secondary' mr={1}>
                      Units Limit
                    </Typography>
                    <InfoOutlinedIcon fontSize='small' />
                  </Grid>
                </Tooltip>
                <TextField
                  name='unitsLimit'
                  error={unitsLimit < 1000 || !Number.isInteger(+unitsLimit)}
                  variant='outlined'
                  placeholder='Units Limit'
                  fullWidth
                  value={unitsLimit}
                  onChange={onChangeField(setUnitsLimit)}
                  disabled={wait || success}
                  InputProps={{ sx: { fontSize: 14 } }}
                  type='number'
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='flex-end'>
              <ButtonComponent color='primary' variant='outlined' disabled={wait} onClick={onClose} text='CANCEL' width={120} marginRight='20px' />
              <ButtonComponent disabled={!isValidUser || wait} onClick={onSendRequest} text={isEdit ? (wait ? 'UPDATING' : 'UPDATE') : wait ? 'CREATING' : 'CREATE'} />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};

export const ClickableItem = ({ children, onClick, isActive, borderColor, paddingRight = 0, width }) => {
  const {
    palette: {
      primary: { main: blue },
      text: { secondary: grey, primary: black },
    },
  } = useTheme();
  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      onClick={onClick}
      sx={{
        position: 'relative',
        paddingLeft: '16px',
        paddingRight,
        width: width || 'calc(50% - 10px)',
        height: 46,
        border: '1px solid',
        borderColor: isActive ? blue : borderColor || grey,
        borderRadius: 1,
        cursor: 'pointer',
        marginBottom: 2,
        '&:hover': !isActive
          ? {
              borderColor: black,
            }
          : {},
      }}
    >
      {children}
    </Grid>
  );
};
