import { Fragment, useMemo, useState } from 'react';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  Grid,
  Box,
  Alert,
  TableSortLabel,
  CircularProgress,
  Button,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { CheckIcon, ClockIcon, CrossIcon, Notification } from 'components';
import { TableSkeleton } from 'components/common/skeletons';
import { UserApi, AdminApi } from 'api';
import { usePaginationState } from 'helpers/hooks';
import { numberWithCommas } from 'helpers';
import { pageSize, dateColWidth, typeColWidth, fixedColWidth } from 'pages/user/dashboard/const';
import { trialRequestsKey, currentUserBalanceKey, incrementKey } from 'store/slices/updateObserver';
import { ConfirmTrialModal } from 'pages/admin/dashboard/confimOrder';
import { squareBtnStyle } from 'pages/admin/dashboard/trialUnitsOrders';

export const TrialRequests = ({ userId, isAdminView = false }) => {
  const dispatch = useDispatch();
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();
  const observer = useSelector((s) => s.updateObserver[trialRequestsKey]);
  const { data, error, loading, totalPages, page, sortBy, sortOrder, onChangePage, onChangeSort } = usePaginationState(
    userId ? AdminApi.getUserTrialRequests : UserApi.getTrialRequests,
    'date',
    'DESC',
    pageSize,
    observer,
    userId
  );
  const [currentWaitId, setCurrentWaitId] = useState('');
  const [confirmData, setConfirmData] = useState(null);
  const [actionError, setActionError] = useState('');

  const createWaitId = (...args) => args.join('-');

  const onSetConfirmData = (obj) => () => setConfirmData(obj);

  const onCloseError = () => setActionError('');

  const onSetOrderStatus = (email, date, id, userId, units, isApprove) => () => {
    setCurrentWaitId(createWaitId(date, id, userId));
    AdminApi.setOrderStatus({ email, units, isTrial: true, usd: 0 }, id, isApprove)
      .then(() => {
        dispatch(incrementKey(trialRequestsKey));
        if (isApprove) {
          dispatch(incrementKey(currentUserBalanceKey));
        }
      })
      .catch(setActionError)
      .finally(() => setCurrentWaitId(''));
  };

  const emptyRows = pageSize - data.length;

  const PageContent = useMemo(() => {
    return data.map(({ date, unitsAmount, isApproved, isRejected, id, email }, index) => (
      <TableRow sx={{ height: 53 }} key={'row-' + date + index + Math.random()}>
        <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
          <Typography variant='body1' color='text.dark'>
            {moment(date).format('DD.MM.YY hh:mm A')}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.dark'>
            Trial Request
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1'>{numberWithCommas(unitsAmount) || '-'}</Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          {!isApproved && !isRejected ? (
            <Fragment key='pending-request'>
              {isAdminView ? (
                <Fragment key='action'>
                  {createWaitId(date, id, userId) === currentWaitId ? (
                    <CircularProgress size={20} />
                  ) : isApproved || isRejected ? null : (
                    <Grid container flexWrap='nowrap'>
                      <Tooltip
                        title={
                          <Typography variant='body2' color='text.dark'>
                            Approve Request
                          </Typography>
                        }
                        arrow
                        placement='top'
                      >
                        <Button
                          size='small'
                          variant='contained'
                          color='greenBtn'
                          sx={{ ...squareBtnStyle, marginRight: 1.5 }}
                          onClick={currentWaitId ? undefined : onSetConfirmData({ email, date, id, userId, isApprove: true })}
                        >
                          <CheckIcon color={contrastText} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          <Typography variant='body2' color='text.dark'>
                            Reject Request
                          </Typography>
                        }
                        arrow
                        placement='top'
                      >
                        <Button
                          size='small'
                          variant='contained'
                          color='redBtn'
                          sx={{ ...squareBtnStyle }}
                          onClick={currentWaitId ? undefined : onSetConfirmData({ email, date, id, userId, isApprove: false })}
                        >
                          <CrossIcon color={contrastText} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}
                </Fragment>
              ) : (
                <Grid container alignItems='center'>
                  <ClockIcon size={14} />
                  <Typography sx={{ marginLeft: '5px' }} variant='body1' color='text.secondary'>
                    Pending
                  </Typography>
                </Grid>
              )}
            </Fragment>
          ) : isRejected ? (
            <Grid container alignItems='center'>
              <CrossIcon size={14} />
              <Typography sx={{ marginLeft: '5px' }} variant='body1' color='text.red'>
                Rejected
              </Typography>
            </Grid>
          ) : (
            <Grid container alignItems='center'>
              <CheckIcon size={14} />
              <Typography sx={{ marginLeft: '5px' }} variant='body1' color='text.green'>
                Granted
              </Typography>
            </Grid>
          )}
        </TableCell>
      </TableRow>
    ));
  }, [data, isAdminView, contrastText, userId, currentWaitId]);

  return (
    <Fragment>
      <Card sx={{ paddingY: 4 }} variant='outlined'>
        <Typography variant='h4' pl={4}>
          Trial Requests
        </Typography>
        {loading ? (
          <TableSkeleton />
        ) : error ? (
          <Box sx={{ padding: 4, paddingBottom: 0 }}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        ) : data.length ? (
          <Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 4, width: dateColWidth }}>
                    <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'date'} onClick={onChangeSort('date')}>
                      <Typography variant='caption' color='text.secondary'>
                        DATE
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: typeColWidth }}>
                    <Typography variant='caption' color='text.secondary'>
                      TYPE
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='caption' color='text.secondary'>
                      AMOUNT
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: fixedColWidth * 2 }}>
                    <Typography variant='caption' color='text.secondary'>
                      STATUS
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ height: 540 }}>
                {PageContent}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 54 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box sx={{ marginBottom: 3 }} />
            <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
              <Pagination shape='rounded' page={page} count={totalPages} onChange={onChangePage} size='small' color='primary' />
            </Grid>
          </Fragment>
        ) : (
          <Box sx={{ padding: 4, paddingBottom: 0 }}>
            <Alert severity='info'>No Info</Alert>
          </Box>
        )}
      </Card>
      <Notification autoHideDuration={6000} key='notifications' open={!!actionError} onClose={onCloseError} message={actionError} type='error' />
      {isAdminView ? (
        <ConfirmTrialModal
          key={`confirm-trial-request-user-${String(!!confirmData)}`}
          open={!!confirmData}
          confirmData={confirmData}
          onClose={onSetConfirmData(null)}
          onSetOrderStatus={onSetOrderStatus}
        />
      ) : null}
    </Fragment>
  );
};
