import { Fragment, useState } from 'react';
import { Card, Tabs, Tab } from '@mui/material';

import { AdminBaseInfo } from 'pages/admin/dashboard/baseInfo';
import { AdminBalance } from 'pages/admin/dashboard/balance';
import { ApiUsersInformation } from 'pages/admin/dashboard/apiUsersInformation';
import { PurchasesHistory } from 'pages/admin/dashboard/purchasesHistory';
import { TrialUnitsOrders } from 'pages/admin/dashboard/trialUnitsOrders';
import { InvoicesHistory } from 'pages/admin/dashboard/invoicesHistory';

import { usePageTitle } from 'helpers/hooks';
import { tabStyles } from 'pages/user/dashboard/tabs';

const TABS = {
  0: { name: 'Clients', environment: 'CLIENT', verified: null },
  1: { name: 'Trial', environment: 'TRIAL', verified: null },
  2: { name: 'Internal', environment: 'INTERNAL', verified: null },
  3: { name: 'Non-Validated', environment: null, verified: false },
};

export const Dashboard = () => {
  usePageTitle('Datai API Panel · API Users');
  const [environment, setEnvironment] = useState(0);

  const onChangeEnvironment = (_, index) => setEnvironment(index);

  const renderTabs = () =>
    Object.entries(TABS).map(([tabNumber, { name, environment }]) => (
      <Tab key={environment} sx={tabStyles} label={name} id={`tab-${tabNumber}`} aria-controls={`tabpanel-${tabNumber}`} />
    ));

  return (
    <Fragment>
      <AdminBaseInfo />
      <AdminBalance />
      <Card variant='outlined' sx={{ height: 60, marginBottom: 3, paddingX: 4 }}>
        <Tabs value={environment} onChange={onChangeEnvironment} aria-label='nav-tabs' variant='scrollable' scrollButtons={false}>
          {renderTabs()}
        </Tabs>
      </Card>
      <ApiUsersInformation key={'api-users-' + TABS[environment].environment} environment={TABS[environment].environment} verified={TABS[environment].verified} />
      <TrialUnitsOrders />
      <PurchasesHistory />
      <InvoicesHistory />
    </Fragment>
  );
};
