import { Card, Dialog, IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

import { CrossIcon } from 'components/icons';
import { ButtonComponent } from 'components/ui';
import rebranding_modal_light from 'rebaranding_modal_light.png';

const rebrandingLsKey = 'rebranding_showed_api';

export const RebrandingModal = () => {
  const {
    primary: { accent_main, contrastText, dark },
    text: { secondary },
  } = useTheme().palette;
  const [showPopup, setShowPopup] = useState(!localStorage.getItem(rebrandingLsKey));

  const onClose = () => {
    localStorage.setItem(rebrandingLsKey, 'true');
    setShowPopup(false);
  };

  return (
    <Dialog PaperProps={{ sx: { maxWidth: 'unset' } }} open={showPopup} onClose={onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card
        sx={{
          width: 632,
          padding: '360px 50px 40px 50px',
          position: 'relative',
          background: `url(${rebranding_modal_light}), ${contrastText}`,
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
        }}
      >
        <IconButton sx={{ position: 'absolute', right: 30, top: 30, cursor: 'pointer' }}>
          <CrossIcon size={16} color={dark} />
        </IconButton>
        <Typography mb='2px' variant='h4'>
          MERLIN API is now
        </Typography>
        <Typography mb='27px' variant='h4' color={accent_main}>
          DATAI API
        </Typography>
        <Typography variant='body1' mb='34px' color={secondary} lineHeight='21px'>
          Enjoy a new look and feel as we continue to evolve your comprehensive blockchain wallet data and insights. The URLs of the endpoints you’re currently using (pointing to
          mymerlin.io) will continue to work seamlessly. No integration updates are required.
          <br />
          <br /> Don’t forget to give us feedback on our{' '}
          <a style={{ color: 'inherit', textDecoration: 'underline' }} href='https://discord.com/invite/datainetwork' target='_blank' rel='noreferrer'>
            Discord
          </a>
          .
        </Typography>
        <ButtonComponent text='GET STARTED' color='primary' variant='contained' width={154} height={40} onClick={onClose} />
      </Card>
    </Dialog>
  );
};
