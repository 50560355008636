import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UserApi, AdminApi, CommonApi } from 'api';
import { setBaseInfo, setBaseInfoError } from 'store/slices/userBaseInfo';
import { setRegistrationOptions, setRegistrationOptionsError } from 'store/slices/registrationOptions';
import { setSubscriptionOptions, setSubscriptionOptionsError } from 'store/slices/subscriptionOptions';

export function useDebounceState(initialValue, debounceTime = 450, cb) {
  const [state, setState] = useState(initialValue);
  const debounceId = useRef(null);
  const _setState = useCallback(
    (value) => {
      clearTimeout(debounceId.current);

      debounceId.current = setTimeout(() => {
        setState(value);
        cb && typeof cb === 'function' && cb();
      }, debounceTime);
    },
    [debounceTime, cb]
  );

  useEffect(() => {
    return () => {
      clearTimeout(debounceId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, _setState];
}

export function useHubspot() {
  useEffect(() => {
    if (process.env.REACT_APP_VERSION === 'PROD') {
      try {
        const headTag = document.getElementsByTagName('head')[0];
        const scriptTag = document.createElement('script');
        scriptTag.src = '//js-eu1.hs-scripts.com/25854157.js';
        scriptTag.id = 'hs-script-loader';
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.defer = true;
        headTag.appendChild(scriptTag);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
}

export const usePageTitle = (title = '') => {
  const originalTitle = 'Datai API Panel';

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = title;

      return () => {
        document.title = originalTitle;
      };
    }
  }, [title]);
};

export const useCurrentUnitsPerDollar = (userId = '') => {
  const [currentUnitsPerDollar, setCurrentUnitsPerDollar] = useState(0);
  const [currentUnitsPerDollarError, setCurrentUnitsPerDollarError] = useState('');
  const [currentUnitsPerDollarLoading, setCurrentUnitsPerDollarLoading] = useState(true);

  useEffect(() => {
    setCurrentUnitsPerDollarLoading(true);
    CommonApi.getUnitsPerDollar(userId)
      .then(setCurrentUnitsPerDollar)
      .catch(setCurrentUnitsPerDollarError)
      .finally(() => setCurrentUnitsPerDollarLoading(false));
  }, [userId]);

  return { currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading };
};

export const usePanelInitialData = (isAdminApp = false) => {
  const dispatch = useDispatch();

  useEffect(() => {
    UserApi.getBaseUserInfo()
      .then((info) => dispatch(setBaseInfo(info)))
      .catch((err) => dispatch(setBaseInfoError(err)));
    (isAdminApp ? AdminApi : UserApi)
      .getRegistrationOptions()
      .then((opt) => dispatch(setRegistrationOptions(opt)))
      .catch((err) => dispatch(setRegistrationOptionsError(err)));

    if (isAdminApp) {
      AdminApi.getSubscriptionOptions()
        .then((opt) => dispatch(setSubscriptionOptions(opt)))
        .catch((err) => dispatch(setSubscriptionOptionsError(err)));
    }
  }, [dispatch, isAdminApp]);
};

export const usePaginationState = (
  asyncGetFc = () => new Promise((res) => res()),
  sortByDefaultValue = 'date',
  sortOrderDefaultValue = 'ASC',
  pageSize = 10,
  updateObserver = 0,
  userId = null
) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState(sortByDefaultValue);
  const [sortOrder, setSortOrder] = useState(sortOrderDefaultValue);

  const onChangeSort = (sortKey) => () => {
    if (sortBy === sortKey) setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    else setSortBy(sortKey);
    setPage(1);
  };

  const onChangePage = (_, nextPage) => setPage(nextPage);

  const getData = (_page = page, _sortBy = sortBy, _sortOrder = sortOrder) => {
    setLoading(true);
    asyncGetFc({
      page: _page - 1,
      pageSize,
      sortBy: _sortBy,
      sortOrder: _sortOrder,
      ...(userId ? { userId } : {}),
    })
      .then(({ content, totalPages }) => {
        setData(content);
        setTotalPages(totalPages);
        setError('');
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData(page, sortBy, sortOrder); // eslint-disable-next-line
  }, [page, sortBy, sortOrder, updateObserver]);

  return { data, error, loading, page, totalPages, sortBy, sortOrder, onChangePage, onChangeSort };
};
