import { Fragment } from 'react';

import { MainInfo } from 'pages/user/dashboard/mainInfo';
import { TabsCard } from 'pages/user/dashboard/tabs';

import { usePageTitle, useCurrentUnitsPerDollar } from 'helpers/hooks';
import { paths } from 'consts';

export const Dashboard = () => {
  usePageTitle(`Datai API Panel · ${paths.dashboard.name}`);
  const { currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading } = useCurrentUnitsPerDollar();

  return (
    <Fragment>
      <MainInfo />
      <TabsCard {...{ currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading }} />
    </Fragment>
  );
};
