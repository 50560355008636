import { Fragment, useState, useMemo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Typography, Grid, Button, Radio, TextField, Alert, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { usePageTitle, useCurrentUnitsPerDollar } from 'helpers/hooks';
import { numberWithCommas, numberFormatter } from 'helpers';
import { CreditCardIcon, Info, USDCIcon, ChewronRightIcon } from 'components';
import { SuccessCard, ErrorCard } from 'pages/user/purchaseUnits/successCard';
import { PurchaseUnitsSkeleton } from 'pages/user/purchaseUnits/skeleton';
import { NumericFormatInput } from 'pages/user/purchaseUnits/formattedInput';
import { ButtonComponent } from 'components/ui';

import { UserApi } from 'api';
import { baseAmounts, amountCardStyles, h2Styles, radioButtonIconStyles, radioButtonStyles, paymentCardStyles, paymentSuccess } from 'pages/user/purchaseUnits/page.const';
import { paths } from 'consts';

export const PurchaseUnits = () => {
  const [searchParams] = useSearchParams();
  const {
    palette: {
      primary: { main: mainBlue, accent_main },
      border: { main: borderColor },
      text: { primary: blackText, secondary: secondaryText },
      background: { content: lightGrey },
      error: { main: red },
    },
    transitions: { duration, easing, create: createTransition },
  } = useTheme();
  const { minPurchasedUnits, email, loading: userInfoLoading, error: userInfoError } = useSelector((s) => s.userBaseInfo);
  const { currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading } = useCurrentUnitsPerDollar();
  usePageTitle(`Datai API Panel · ${paths.purchaseUnits.name}`);
  const navigate = useNavigate();
  //amount states
  const [standartAmount, setStandartAmont] = useState(baseAmounts[0]);
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [amount, setAmount] = useState(0);
  //payment flow states
  const [paymentType, setPaymentType] = useState('STRIPE');
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrroMessage] = useState('');
  //global loading/common error
  const success = searchParams.get(paymentSuccess) === 'true';
  const error = errorMessage || currentUnitsPerDollarError || userInfoError;
  const loading = currentUnitsPerDollarLoading || userInfoLoading;
  //
  const amountInUsd = +(+amount * (1 / currentUnitsPerDollar));
  const amountError = !amount || +amount < minPurchasedUnits;
  const integerError = !Number.isInteger(+amount * (1 / currentUnitsPerDollar));
  const totalToPay = isCustomAmount ? amountInUsd : standartAmount;

  //style states
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);

  const onChangeInputFocused = (nextVal) => () => setInputFocused(nextVal);
  const onChangeInputHovered = (nextVal) => () => setInputHovered(nextVal);

  const onChangeAmount = ({ target: { value } }) => {
    setAmount(value);
  };

  const onSelectStandartAmount = (nextVal) => () => {
    setIsCustomAmount(false);
    setStandartAmont(nextVal);
  };

  const onChangePaymentType = (nextType) => () => setPaymentType(nextType);

  const onSelectCustomAmount = () => setIsCustomAmount(true);

  const onBack = () => navigate(`/${paths.user.path}/${paths.dashboard.path}`);

  //requests types fcs

  const onPayCoinBase = (usd, unitsAmount) => async () => {
    const data = {
      name: 'Datai API Units',
      description: 'Buy Datai API Units',
      amount: usd,
      currency: 'USD',
      customer_id: email,
      redirect_url: `${window.location.origin}/user/dashboard/purchase-units?paymentSuccess=true&amount=${unitsAmount}&isRequest=false`,
      cancel_url: `${window.location.origin}/user/dashboard`,
    };
    setWait(true);
    const res = await UserApi.createCoinBasePayment(data)
      .catch(setErrroMessage)
      .finally(() => setWait(false));
    if (res) {
      window.location.href = res.hosted_url;
    }
  };

  const onPayStripe = (usd, unitsAmount) => async () => {
    const data = {
      name: 'Datai API Units',
      description: 'Buy Datai API Units',
      amount: usd,
      currency: 'USD',
      customer_id: email,
      redirect_url: `${window.location.origin}/user/dashboard/purchase-units?paymentSuccess=true&amount=${unitsAmount}&isRequest=false`,
      cancel_url: `${window.location.origin}/user/dashboard`,
    };
    setWait(true);
    const res = await UserApi.createStriptePayment(data)
      .catch(setErrroMessage)
      .finally(() => setWait(false));
    if (res) {
      window.location.href = res.url;
    }
  };

  const paymentsMap = useMemo(
    () => ({
      STRIPE: {
        title: 'Credit Card',
        subtitle: 'Stripe',
        icon: <CreditCardIcon />,
        action: onPayStripe,
        description: 'You will be redirected to Stripe to finish the payment, units will be added automatically.',
        actionName: 'Confirm and Proceed to Payment',
      },
      COINBASE: {
        title: 'USDC',
        subtitle: 'Coinbase',
        icon: <USDCIcon />,
        action: onPayCoinBase,
        description: 'You will be redirected to Coinbase to finish the payment, units will be added automatically.',
        actionName: 'Confirm and Proceed to Payment',
      },
    }), // eslint-disable-next-line
    []
  );

  const renderBaseAmounts = () =>
    baseAmounts.map((a, i) => {
      const isActive = a === standartAmount && !isCustomAmount;

      return (
        <Card
          key={`amount-${a}-${i}`}
          onClick={onSelectStandartAmount(a)}
          variant='outlined'
          sx={{
            ...amountCardStyles,
            ...(isActive ? { borderColor: mainBlue } : {}),
            transition: createTransition('border-color', { duration: duration.standard, easing: easing.easeInOut }),
          }}
        >
          <Typography variant='h3' sx={h2Styles} noWrap>
            {numberFormatter(a * currentUnitsPerDollar)}
          </Typography>
          <Typography variant='subtitle1' color='text.secondary' noWrap>
            ${numberWithCommas(a)}
          </Typography>
          <Radio value={a} checked={isActive} sx={radioButtonStyles} />
        </Card>
      );
    });

  const renderPaymentTypes = () =>
    Object.entries(paymentsMap).map(([key, { title, subtitle, icon }], i) => {
      const isActive = key === paymentType;
      return (
        <Card
          key={`payment-type-${key}-${i}`}
          onClick={onChangePaymentType(key)}
          variant='outlined'
          sx={{
            transition: createTransition('border-color', { duration: duration.standard, easing: easing.easeInOut }),
            ...paymentCardStyles,
            ...(isActive ? { borderColor: mainBlue } : {}),
          }}
        >
          <Grid container alignItems='center' sx={{ height: '100%' }}>
            <Grid sx={{ width: 42, height: 42, bgcolor: `${accent_main}10`, marginRight: 2.5, borderRadius: 21 }} alignItems='center' justifyContent='center' container>
              {icon}
            </Grid>
            <Grid flexDirection='column' justifyContent='center' sx={{ height: '100%', width: 'calc(100% - 95px)' }}>
              <Typography variant='h4' mb='5px' noWrap>
                {title}
              </Typography>
              <Typography variant='subtitle1' color='text.secondary' noWrap>
                {subtitle}
              </Typography>
            </Grid>
            <Radio value={key} checked={isActive} sx={{ marginLeft: 'auto', ...radioButtonIconStyles }} />
          </Grid>
        </Card>
      );
    });

  useEffect(() => {
    if (!amount && minPurchasedUnits) setAmount(minPurchasedUnits);
  }, [minPurchasedUnits, amount]);

  return (
    <Fragment>
      <Card variant='outlined' sx={{ height: 65, paddingY: 2, paddingX: 4, marginBottom: 5 }}>
        <Grid container justifyContent='space-between' alignItems='center' sx={{ height: '100%' }}>
          <Button onClick={onBack} variant='outlined' sx={{ width: 32, height: 32, minWidth: 32, padding: 0, justifyContent: 'center' }}>
            <ArrowBackIcon fontSize='small' />
          </Button>
          <Typography variant='h4' sx={{ marginLeft: 3.34, marginRight: 'auto' }}>
            {paths.purchaseUnits.name}
          </Typography>
        </Grid>
      </Card>
      {loading ? (
        <PurchaseUnitsSkeleton />
      ) : error ? (
        <ErrorCard message={error} />
      ) : success ? (
        <SuccessCard />
      ) : (
        <Fragment key='payment-flow'>
          <Card variant='outlined' sx={{ padding: 4, paddingBottom: 5.5, marginBottom: 5, height: 210 }}>
            <Typography variant='h4' sx={{ marginBottom: '20px' }}>
              Choose amount of units
            </Typography>
            <Grid container alignItems='center' justifyContent='space-between'>
              {renderBaseAmounts()}
              <Card
                variant='outlined'
                onClick={onSelectCustomAmount}
                sx={{
                  ...amountCardStyles,
                  ...(isCustomAmount ? { borderColor: mainBlue } : {}),
                }}
              >
                <Typography variant='h3' sx={h2Styles} noWrap>
                  Custom
                </Typography>
                <Typography variant='subtitle1' color='text.secondary' noWrap>
                  Up to request
                </Typography>
                <Radio value={true} checked={isCustomAmount} sx={radioButtonStyles} />
              </Card>
            </Grid>
          </Card>
          {isCustomAmount ? (
            <Card variant='outlined' sx={{ minHeight: 140, padding: 4, paddingBottom: 5, marginBottom: 5 }}>
              <Typography variant='h4' sx={{ marginBottom: '20px' }}>
                Custom amount
              </Typography>
              <Grid container>
                <Grid sx={{ width: 236, height: 42, marginRight: 3, position: 'relative' }}>
                  <TextField
                    fullWidth
                    sx={{ height: 42 }}
                    value={amount}
                    onChange={onChangeAmount}
                    error={amountError}
                    disabled={wait}
                    onFocus={onChangeInputFocused(true)}
                    onBlur={onChangeInputFocused(false)}
                    onMouseEnter={onChangeInputHovered(true)}
                    onMouseLeave={onChangeInputHovered(false)}
                    InputProps={{ sx: { paddingLeft: '4px', paddingRight: 13.5, height: 42 }, inputComponent: NumericFormatInput }}
                  />
                  <Grid
                    container
                    sx={{
                      width: 77,
                      height: '100%',
                      borderLeft: `1px solid ${amountError ? red : inputFocused ? mainBlue : inputHovered ? blackText : borderColor}`,
                      position: 'absolute',
                      right: 0,
                      top: 0,
                    }}
                  >
                    <Typography variant='subtitle1' color='text.secondary' m='auto'>
                      units
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  sx={{ width: 260, height: 42, border: `1px solid ${borderColor}`, borderRadius: 1, paddingX: '18px', backgroundColor: lightGrey }}
                  container
                  alignItems='center'
                >
                  <Typography variant='subtitle1' noWrap>
                    ${numberWithCommas(amountInUsd)}
                  </Typography>
                </Grid>
              </Grid>
              {amountError || integerError ? (
                <Alert sx={{ marginTop: 3 }} severity='error'>
                  {amountError
                    ? `Please increase the amount of units to at least $${minPurchasedUnits / currentUnitsPerDollar} minimum purchase.`
                    : 'Please adapt the amount of units to reach a round USD price.'}
                </Alert>
              ) : null}
            </Card>
          ) : null}
          <Card variant='outlined' sx={{ padding: 4, paddingBottom: '33px', marginBottom: 5, height: 180 }}>
            <Typography variant='h4' sx={{ marginBottom: '20px' }}>
              Payment method
            </Typography>
            <Grid container alignItems='center' justifyContent='space-between'>
              {renderPaymentTypes()}
            </Grid>
          </Card>
          <Card variant='outlined' sx={{ padding: 4, paddingBottom: 5 }}>
            <Typography variant='h4' sx={{ marginBottom: '20px' }}>
              Proceed to payment
            </Typography>
            <Grid container alignItems='center' flexWrap='nowrap' sx={{ height: 42, marginBottom: 4, border: `1px solid ${borderColor}`, borderRadius: 1 }}>
              <Grid container wrap='nowrap' alignItems='center' sx={{ width: 236, height: 42, borderRadius: 1, bgcolor: `${accent_main}10`, paddingX: 3, marginRight: '20px' }}>
                <Typography variant='subtitle1' color='text.secondary' mr='3px'>
                  Total:
                </Typography>
                <Typography variant='subtitle1' noWrap>
                  ${numberWithCommas(totalToPay)}
                </Typography>
              </Grid>
              <Info size={16} />
              <Typography variant='subtitle1' color='text.secondary' ml={1} noWrap>
                {paymentsMap[paymentType].description}
              </Typography>
            </Grid>
            <ButtonComponent
              disabled={(isCustomAmount && (amountError || integerError)) || wait}
              onClick={paymentsMap[paymentType].action(totalToPay, totalToPay * currentUnitsPerDollar)}
              endIcon={<ChewronRightIcon color={((isCustomAmount && (amountError || integerError)) || wait) && secondaryText} />}
              text={paymentsMap[paymentType].actionName}
              width={375}
              height={42}
            />
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};
