import { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme, Dialog, TextField, Grow, Alert, Grid, Typography, Card } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { AdminApi } from 'api';
import { numberWithCommas } from 'helpers';
import { topUpHistoryKey, incrementKey } from 'store/slices/updateObserver';
import { ButtonComponent } from 'components/ui';
import { CheckBoxIcon, CheckBoxCheckedIcon } from 'components/icons';
import { NumericFormatInput } from 'pages/user/purchaseUnits/formattedInput';

export const AddUnitsModal = ({ open, onClose, updateData, email, currentUnitsPerDollar }) => {
  const {
    palette: {
      text: { black, secondary },
    },
  } = useTheme();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(currentUnitsPerDollar || 2500);
  const [wait, setWait] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [errorMessage, setErrroMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const onChangeIsTrial = (event) => {
    setIsTrial(event.target.checked);
  };

  const amountInUsd = +(+amount * (1 / currentUnitsPerDollar)).toFixed(2);

  const onChangeAmount = ({ target: { value } }) => {
    errorMessage && setErrroMessage('');
    setAmount(value);
  };

  const onSendRequest = async () => {
    setWait(true);
    const res = await AdminApi.addApiUserUnits({ email, isTrial, ...(isTrial ? { usd: 0, units: +amount } : { usd: amountInUsd }) })
      .catch(setErrroMessage)
      .finally(() => setWait(false));
    if (res) {
      updateData();
      setSuccess(true);
      dispatch(incrementKey(topUpHistoryKey));
    }
  };

  const onEnterPress = (e) => e.key === 'Enter' && onSendRequest();

  const onCloseModal = (_, reason) => {
    if (reason === 'backdropClick' && wait) return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, padding: 4, paddingBottom: 5 }}>
        <Typography mb='12px' variant='h4'>
          Add Units
        </Typography>
        <Typography mb='12px' variant='body2' component='p' color='text.secondary'>
          Please provide the amount of units you would like to add and confirm by clicking on "Add".
        </Typography>
        {errorMessage ? (
          <Grow in={!!errorMessage}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Grow>
        ) : success ? (
          <Grow in={success}>
            <Alert severity='success'>Units successfully added.</Alert>
          </Grow>
        ) : (
          <Fragment>
            <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              Amount of Units
            </Typography>
            <TextField
              autoFocus={true}
              variant='outlined'
              fullWidth
              value={+amount}
              onChange={onChangeAmount}
              error={!amount || amount < (isTrial ? 0 : currentUnitsPerDollar)}
              disabled={wait || success}
              onKeyDown={onEnterPress}
              InputProps={{ sx: { fontSize: 14 }, inputComponent: NumericFormatInput }}
            />
            <Typography sx={{ marginTop: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              {+amount < currentUnitsPerDollar && !isTrial
                ? `Minimum amount is ${currentUnitsPerDollar} units`
                : currentUnitsPerDollar && +amount >= currentUnitsPerDollar && !isTrial
                ? `$${numberWithCommas(amountInUsd)}`
                : isTrial
                ? 'Free Units'
                : ''}
            </Typography>
            <FormControlLabel
              sx={{ marginBottom: '12px', marginTop: '5px' }}
              control={<Checkbox value={isTrial} onChange={onChangeIsTrial} icon={<CheckBoxIcon />} checkedIcon={<CheckBoxCheckedIcon />} />}
              label={<Typography color={isTrial ? black : secondary}>Trial Units</Typography>}
            />

            <Grid container justifyContent='space-between'>
              <ButtonComponent color='primary' variant='outlined' disabled={wait} onClick={onClose} text='CANCEL' width={76} />
              <ButtonComponent onClick={onSendRequest} disabled={isTrial ? !amount || wait : !amount || amount < currentUnitsPerDollar || wait} text='ADD' width={184} />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};
